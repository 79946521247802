<section class="relative bg-white
us:w-80 us:min-h-144px us:h-auto us:p-4
lg-landscape:w-410px lg-landscape:min-h-144px lg-landscape:h-auto lg-landscape:p-4">
  <fa-icon (click)="close()"
           class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-[#FFA101] lg-landscape:text-base"
           [icon]="faClose">
  </fa-icon>
  <p class="relative font-Lato-Regular text-sm font-semibold px-2">{{msg | translate}}</p>
  <div class="relative w-full h-px bg-gray-300 my-4"></div>
  <div class="relative flex justify-start items-center mt-6">
    <button (click)="close(true)" class="relative bg-[#FFC35D] font-Lato-Regular font-semibold text-sm border-none active:ring-0 active:border-none focus:ring-0 focus:border-none
        px-6 py-2 rounded-3px">{{btn_ok_text | translate}}</button>
    <button *ngIf="btn_cancel_txt" (click)="close()" class="relative bg-white font-Lato-Regular font-semibold text-sm ml-5 border-none active:ring-0 active:border-none focus:ring-0 focus:border-none
        px-6 py-2 rounded-3px">{{btn_cancel_txt | translate}}</button>
  </div>
</section>
