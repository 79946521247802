<div class="bg-gray-100 h-full">
  <!-- Header -->
  <app-header>

  </app-header>
  <div #section class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px]">

      <div class="my-8">
        <h3 class="font-Lato-Bold text-[24px] mb-4">
          {{'CONFIRM_PHONE_NUMBER' | translate}}
        </h3>
        <p class="font-Lato-Regular text-[14px]">
          {{'CONFIRM_PHONE_MESSAGE' | translate}}
        </p>
      </div>
      <!-- Form -->
      <form [formGroup]="phoneForm">
        <div class="text-start mb-10">
          <ngx-mat-intl-tel-input
            class="mat-intl-tel-input-class py-4 relative text-gray-#727272 bg-white font-Lato-Regular
            pl-4 text-base flex flex-1 items-center"
            [enablePlaceholder]="true"
            [preferredCountries]="['us', 'cu']"
            inputPlaceholder="{{'PHONE_NUMBER' | translate}}*"
            (countryChanged)="changePhone($event)"
            formControlName="phone"
            [enableSearch]="true"
            name="phone"
          >
          </ngx-mat-intl-tel-input>
          <div *ngIf="phoneForm.get('phone')?.invalid && (phoneForm.get('phone')?.dirty || phoneForm.get('phone')?.touched)">
            <div class="relative font-Lato-Regular text-sm text-red-300 h-3" *ngIf="phoneForm.get('phone')?.hasError('required')">{{ 'REQUIRED' | translate }}</div>
            <div class="relative font-Lato-Regular text-sm text-red-300 h-3" *ngIf="phoneForm.get('phone')?.hasError('validatePhoneNumber')">{{ 'INVALID_PHONE_NUMBER' | translate }}</div>
          </div>
        </div>
          <mat-radio-group class="flex flex-col text-start"
                           formControlName="notificationType"
          >
            <mat-radio-button class="bg-white font-Lato-Bold w-full px-4 py-3"
                              value="1">{{'PHONE_SMS' | translate}}</mat-radio-button>
            <!--<mat-radio-button class="bg-white font-Lato-Bold w-full px-4 py-3 mb-1"
                              value="2">{{'WHATSAPP' | translate}}</mat-radio-button>-->
          </mat-radio-group>

        <!-- Submit Button -->
        <button [ngClass]="{'opacity-50' : spin || phoneForm.invalid, 'cursor-not-allowed' : spin || phoneForm.invalid}"
                (click)="onConfirm()"
                class="mt-8 rounded-full bg-[#FF6D03] py-2 text-[19px] text-white mb-6 w-full"
                [disabled]="spin || phoneForm.invalid">
          <p *ngIf="!spin" class="font-Lato-Bold text-[19px]">
            {{'CONFIRM' | translate}}
          </p>
          <div class="flex flex-row justify-center items-center">
            <fa-icon *ngIf="spin"
                     class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
          </div>
        </button>
      </form>
    </div>
  </div>
</div>
