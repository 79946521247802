<!-- Header -->
<app-header>

</app-header>
<div class="bg-gray-100 min-h-screen">
<div #section class="bg-gray-100 justify-center flex flex-col content-center items-center text-center">

  <div class="w-[333px]">
    <div class="flex flex-col items-center my-4">
      <h1 class="font-Lato-Bold text-[24px]">
        {{ 'LOG_IN' | translate }}
      </h1>
      <span class="font-Lato-Regular font-[14px] text-[#252525]">
        {{ 'EXPLORE_DISCOVER_AND_BUY_MESSAGE' | translate }}
      </span>
    </div>
    <!-- Form -->
    <form [formGroup]="loginForm" *ngIf="showCaptcha">
      <!-- Email Input -->
      <div class="mb-4 flex items-center w-full">
        <input type="email" id="email" name="email"
               formControlName="email"
               class="w-full px-4 py-4 focus:outline-none
               font-Lato-Light placeholder-[##727272]"
               placeholder="{{'ELECTRONIC_EMAIL' | translate}}*">
        <fa-icon [icon]="faInfoCircle" matTooltip="{{'PASSKEY_TOOLTIP' | translate}}" class="ml-2 fa-xl" style="color: rgb(24 57 108)" *ngIf="showCaptcha"></fa-icon>
      </div>
    </form>
    <div *ngIf="showCaptcha && loginForm.valid" class="flex flex-col items-center mt-4">
      <span class="font-Lato-Regular text-[16px]">
        {{ 'COMPLETE_TO_CONTINUE' | translate }}
      </span>
      <div class="w-full mx-auto h-auto py-1 ">
        <app-captcha class="relative w-full"
                     (onCaptchaCheck)="captchaCapture($event,emailControl.value,redirect)"></app-captcha>
      </div>
      <button *ngIf="tryAgain && showCaptcha"
              [ngClass]="{'cursor-not-allowed': !tryAgain, 'opacity-50':!tryAgain }"
              [disabled]="!tryAgain" (click)="resendCheck(emailControl.value,redirect)"
              class="mt-4 rounded-full bg-[#FF6D03] py-2 text-[19px] text-white w-full
            flex justify-center">
        <span class="font-Lato-Bold">
          {{ 'TRY_AGAIN' | translate }}
        </span>
      </button>
      <div class="py-2">
        <span class="text-blue-600 font-Lato-Regular text-[16px] hover:cursor-pointer" (click)="useOtherMethod()">
          {{ 'USE_OTHER_METHOD' | translate }}
        </span>
      </div>
    </div>
    <!-- Login Options -->
    <div *ngIf="!showCaptcha" class="flex flex-col items-center mt-4">
      <div class="mb-4">
        <h2 class="font-Lato-Bold text-[18px]">
          {{'HOW_DO_YOU_WANT_TO_LOGIN' | translate}}
        </h2>
      </div>
      <button (click)="navigateTo('/sign-in-password', redirect, '',emailControl.value,managementString)"
              class="rounded-full bg-[#FF6D03] py-2 text-[19px] text-white w-full flex justify-center items-center h-full">
        {{ 'PASSWORD' | translate }}
      </button>
      <button (click)="navigateTo('/sign-in-code', redirect, '',emailControl.value, managementString)"
              class="my-4 rounded-full bg-[#FF6D03] py-2 text-[19px] text-white w-full flex justify-center">
        {{ 'CODE_FOR_EMAIL' | translate }}
      </button>
      <div class="flex items-center w-full">
      <button (click)="startCaptcha()"
              class="rounded-full bg-[#FF6D03] py-2 text-[19px] text-white w-full flex justify-center">
        {{ 'PASSKEY' | translate }}
        <fa-icon [icon]="faFingerprint" class="text-white ml-4"></fa-icon>
        <img src="assets/FaceId-White.svg" alt="Face ID" class="w-6 h-6 p-0.5 ml-1">
      </button>
      <fa-icon [icon]="faInfoCircle" matTooltip="{{'PASSKEY_TOOLTIP' | translate}}" class="ml-2 fa-xl" style="color: rgb(24 57 108)"></fa-icon>
      </div>
    </div>
    <div class="mt-4" *ngIf="!showCaptcha">
      <h3 class="font-Lato-Bold text-[24px] mb-4">
        {{ 'ARE_YOU_NEW' | translate }}
      </h3>

      <p class="mb-5 font-Lato-Regular text-[14px]">
        {{ 'CREATE_ACCOUNT_MESSAGE' | translate }}
      </p>

      <div (click)="navigateTo('/register', redirect)"
           class=" rounded-full border-[#FF6D03] border-2 py-2 mb-5 w-full flex  justify-center hover:cursor-pointer">
        <p class="font-Lato-Bold text-[19px] text-[#FF6D03]">
          {{ 'CREATE_ACCOUNT' | translate }}
        </p>
      </div>
    </div>
    <p class="font-Lato-Regular">
      {{ 'AGREE' | translate }}
      <a href="https://www.katapulk.com/contents/terms" target="_blank" class="text-blue-600">
        {{ 'AGREE_SCND' | translate }}
      </a>
    </p>
    <div class="h-6 justify-center flex md:hidden">
      <button class="mt-4 font-Lato-Regular text-[#18396C] px-5 text-[19px] underline mb-6" (click)=navigateToApp()>
        {{'GO_BACK' | translate}}
      </button>
    </div>
  </div>
  <div *ngIf="loading" class="fixed inset-0 bg-[rgb(0_0_0_/_32%)] flex items-center justify-center z-50 opacity-100">
    <fa-icon [icon]="faSpinner" class="text-white text-5xl animate-spin"></fa-icon>
  </div>
</div>
</div>
