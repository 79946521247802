import {Component, Input} from '@angular/core';
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-password-input',
  standalone: true,
  imports: [
    FaIconComponent,
    ReactiveFormsModule,
    TranslateModule,
    NgIf
  ],
  templateUrl: './password-input.component.html',
  styleUrl: './password-input.component.css'
})
export class PasswordInputComponent {
  iconType = faEyeSlash;
  showPassword: boolean = true;
  fieldType = 'password';

  @Input() passwordControl: FormControl<string | null> = new FormControl("");
  @Input() placeholder: string = '';

  showHidePassword() {
    this.showPassword = !this.showPassword;
    if (!this.showPassword) {
      this.iconType = faEye;
      this.fieldType = 'text';
    } else {
      this.iconType = faEyeSlash;
      this.fieldType = 'password';
    }
  }
}
