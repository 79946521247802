import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Base64UrlService {

  constructor() { }

  public toBase64Url(arrayBuffer: ArrayBuffer): string {
    return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer))).replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");
  }

  public fromBase64Url(value: string): Uint8Array {
    return Uint8Array.from(atob(value.replace(/-/g, "+").replace(/_/g, "/")), c => c.charCodeAt(0));
  }

  public base64StringToUrl(base64String: string): string {
    return base64String.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");
  }
}
