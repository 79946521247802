import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {faSpinner, faFingerprint, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {TranslateModule} from "@ngx-translate/core";
import {Subject, takeUntil} from "rxjs";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {CookieService} from "ngx-cookie-service";
import {PasswordInputComponent} from "../../../shared/components/password-input/password-input.component";
import {NavigationService} from "../../../shared/services/navigation.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {PasskeyService} from "../../../shared/services/passkey.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    CaptchaComponent,
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    PasswordInputComponent,
    HeaderComponent,
    MatTooltip
  ],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.css'
})
export class SignInComponent implements OnDestroy, OnInit {
  spin: boolean = false;
  faSpinner = faSpinner;
  faFingerprint = faFingerprint;
  faInfoCircle = faInfoCircle;
  token: string | null = null;
  redirect: any;
  management: boolean = false;
  managementString: string = '';
  showLoginOptions: boolean = false;
  loading: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  showCaptcha: boolean = false;
  tryAgain: boolean = false;
  @ViewChild('section') section: ElementRef | undefined;

  public loginForm = this.fb.group(
    {
      email: [{
        value: '',
        disabled: false
      }, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    }
  );
  public emailControl: FormControl = this.loginForm.get('email') as FormControl;


  constructor(
    private fb: FormBuilder,
    private passkeyService: PasskeyService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private navigationService: NavigationService,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.showCaptcha = false;
    this.tryAgain = false;
    const paramMap = this.route.snapshot.queryParamMap
    if (paramMap.get('email') != null) {
      this.loginForm.patchValue({email: paramMap.get('email')});
      this.showLoginOptions = true;
    }
    if (typeof window !== 'undefined') {
    const savedEmail = localStorage.getItem('lastEmail');
    if (savedEmail) {
      this.loginForm.patchValue({email: savedEmail});
    }}
    if (isAllowed(paramMap.get('redirect'))) {
      this.redirect = paramMap.get('redirect');
      this.managementString = paramMap.get('management') ?? "false";
      this.management = this.managementString === "true";
      this.cookieService.deleteAll();
      this.cookieService.set('management', this.managementString);
    } else {
      this.router.navigate(['/not-found']);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete(); //  You can replace this with this.destroy$.complete() as well
  }

  navigateTo(url: string, redirect: string, code?: string, email?: string, management?: string) {
    if (this.management)
      this.navigationService.navigateTo({
        url: url,
        redirect: redirect,
        code: code,
        email: email,
        management: management
      });
    else
      this.navigationService.navigateTo({
        url: url,
        redirect: redirect,
        code: code,
        email: email
      });
  }

  private async checkPasskey(email: string, redirect: string) {
    try {
      this.passkeyService.checkPasskey(email, this.token!)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (result) => {
            if (result?.success) {
              if (result.data.canRegisterOrUsePasskey && result.data.hasRegisteredAnyPasskey) {
                this.passkeyService.getAssertionOptions(email)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe({
                    next: async (options) => {
                      if (options?.success) {
                        await this.loginPasskey(email, options);
                      } else {
                        this.notificationService.showAndSubscribe(options.error.message, 'OK');
                        this.loading = false;
                      }
                    }
                  });
              } else if (result.data.canRegisterOrUsePasskey) {
                this.registerPasskey(email, redirect);
              }
            } else {
              this.notificationService.showAndSubscribe(result.error.message, 'OK');
              this.loading = false;
            }
          }
        });
    } catch (error) {
      await this.handleError(error);
      this.loading = false;
    }
  }

  private async loginPasskey(email: string, options: any) {
    try {
      const login = await this.passkeyService.login(email, options.data?.jsonCredentialOptions);
      if (login) {
        login.pipe(takeUntil(this.destroy$))
          .subscribe({
            next: async (loginResult) => {
              if (loginResult.success) {
                this.navigateTo('/login-success', this.redirect, loginResult.data.code);
              } else {
                this.notificationService.showAndSubscribe(loginResult.error.message, 'OK');
                this.loading = false;
                return;
              }
            },
            error: async (error) => {
              await this.handleError(error);
              this.loading = false;
            }
          });
      }
    } catch (error) {
      await this.handleError(error, async () => {
        this.registerPasskey(email, this.redirect);
      });
    } finally {
      this.loading = false;
    }
  }

  private registerPasskey(email: string, redirect: string) {
    this.passkeyService.startAttestation(email)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async (result) => {
          if (result.success) {
            this.navigateTo('/sign-in-passkey', redirect, '', email);
          } else {
            this.notificationService.showAndSubscribe(result.message, 'OK');
            this.loading = false;
          }
        }
      });
  }

  private async handleError(error: any, onNotAllowedError?: () => Promise<void>) {
    const language = this.getBrowseeLanguage();
    const messages = {
      en: {
        invalidStateError: 'The authenticator is in an invalid state. Please try again.',
        notAllowedError: 'Authentication was not allowed. Please try again.',
        noPasskey: 'An error occurred while retrieving your device credentials. This may be because your device is not registered.\n' +
          'Would you like to register new credentials?',
        unknownError: 'An unknown error occurred. Please try again later.',
        securityError: 'The relying party ID is not a registrable domain suffix of, nor equal to the current domain.'
      },
      es: {
        invalidStateError: 'El autenticador está en un estado no válido. Por favor, inténtelo de nuevo.',
        notAllowedError: 'La autenticación no fue permitida. Por favor, inténtelo de nuevo.',
        noPasskey: 'Se ha producido un error al obtener las credenciales de su dispositivo. Esto puede deberse a que su dispositivo no está registrado.\n' +
          '¿Desea registrar nuevas credenciales?',
        unknownError: 'Ocurrió un error desconocido. Por favor, inténtelo más tarde.',
        securityError: 'El ID de la parte confiante no es un sufijo de dominio registrable ni igual al dominio actual.'
      }
    };

    const selectedMessages = messages[language];
    if (error instanceof Error) {
      switch (error.name) {
        case 'InvalidStateError':
          this.notificationService.showAndSubscribe(selectedMessages.invalidStateError, 'OK');
          break;
        case 'NotAllowedError':
          if (onNotAllowedError) {
            const userResponse = await this.notificationService.showAndConfirm(selectedMessages.noPasskey, 'YES', 'NO');
            if (userResponse) {
              await onNotAllowedError();
            } else {
              this.loading = false;
            }
          } else {
            this.notificationService.showAndSubscribe(selectedMessages.notAllowedError, 'OK');
          }
          break;
        case 'SecurityError':
          this.notificationService.showAndSubscribe(selectedMessages.securityError, 'OK');
          break;
        default:
          this.notificationService.showAndSubscribe(error.message, 'OK');
          break;
      }
    } else {
      this.notificationService.showAndSubscribe(selectedMessages.unknownError, 'OK');
    }
  }

  private getBrowseeLanguage() {
    const language = navigator.language || navigator.languages[0];
    return language.startsWith('es') ? 'es' : 'en';
  }

  async captchaCapture(token: string, email: string, redirect: string) {
    this.loading = true;
    this.tryAgain = true;
    this.token = token;
    if (this.loginForm.valid)
      localStorage.setItem('lastEmail', email);
    await this.checkPasskey(email, redirect);
  }

  async resendCheck(email: string, redirect: string) {
    await this.captchaCapture(this.token!, email, redirect);
  }

  startCaptcha() {
    this.showCaptcha = true;
  }

  useOtherMethod() {
    this.showLoginOptions = true;
    this.showCaptcha = false;
    this.tryAgain = false;
    this.spin = false;
  }
  navigateToApp() {
    location.href = this.redirect;
  }
}
