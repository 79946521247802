import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {ActivatedRoute, Router} from "@angular/router";
import {JsonPipe, NgClass, NgIf} from "@angular/common";
import {AuthService} from "../../../shared/services/auth.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {CaptchaModalComponent} from "../../../shared/components/captcha-modal/captcha-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-confirm-register',
  standalone: true,
  imports: [
    CaptchaComponent,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    JsonPipe,
    NgIf,
    FormsModule,
    FaIconComponent,
    HeaderComponent
  ],
  templateUrl: './confirm-register.component.html',
  styleUrl: './confirm-register.component.css'
})
export class ConfirmRegisterComponent implements OnInit, OnDestroy {
  faSpinner = faSpinner;
  redirect: any;
  email: any;
  token: any;
  spin: any;
  fromLogin: boolean = false;
  disableResendCode: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();


  @ViewChild('section')
  section: any;

  inputControl = new FormControl(null, Validators.required);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    const queryParamsMap = this.route.snapshot.queryParamMap;
    if (queryParamsMap.get('redirect')) {
      if (isAllowed(queryParamsMap.get('redirect'))) {
        this.redirect = queryParamsMap.get('redirect');
        this.email = queryParamsMap.get('email');
        this.fromLogin = queryParamsMap.get('fromLogin') === "true";
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  async onConfirm() {
    let code: string | null = this.inputControl?.value;
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();

    const req = {
      "email": this.email.trim(),
      "code": (code ?? '').toUpperCase(),
      "captchaToken": token
    }
    if (token) {
      this.spin = true;
      this.authService.confirmEmail(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe((resp: any) => {
        if (resp?.responseCode === 'MissingPhone' || resp?.responseCode === 'UnconfirmedPhone') {
          const code = resp?.data?.code
          this.notificationService.showAndSubscribe(resp?.message,
            'ACCEPT', '', false, '/confirm-phone',
            {redirect: this.redirect, code: code, fromLogin: this.fromLogin});
        } else if (resp?.data) {
          const code = resp?.data?.code;
          this.spin = false;
          this.router.navigate(
            ['/register-success'],
            {
              queryParams: {
                redirect: this.redirect,
                code: code,
              }, replaceUrl: true
            }
          );
        } else {
          this.notificationService.showAndSubscribe(resp?.error?.message, 'ACCEPT');
          this.spin = false;
        }
      });
    }
  }

  async resendEmail() {
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();
    const req = {
      "email": this.email,
      "captchaToken": token
    }

    if (token) {
      this.spin = true;
      this.authService.resendEmailToken(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe((resp) => {
        this.spin = false;
        this.disableResendCode = true;
        setTimeout(() => {
          this.disableResendCode = false;
        }, 10000);

      });
    }
  }
}
