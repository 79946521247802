<div class="h-full bg-gray-100">
  <!-- Header -->
  <app-header>

  </app-header>
  <div #section class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px]">

      <div class="flex flex-col items-center my-8">
        <h1 class="font-Lato-Bold text-[24px]">
          {{'LOG_IN' | translate}}
        </h1>
        <span class="mt-4 font-Lato-Regular font-[14px] text-[#252525]">
          {{'EXPLORE_DISCOVER_AND_BUY_MESSAGE' | translate}}
        </span>
      </div>
      <!-- Form -->
      <form class="py-5" >
        <!-- Select for code -->
        <div class="relative mb-4 text-start">
          <mat-select hideSingleSelectionIndicator class="w-full text-[#727272] px-6 py-4 focus:outline-none bg-white
                 font-Lato-Light text-[##727272]" placeholder="{{'SEND_CODE_METHOD' | translate}}*"
                      [formControl]="methodSelectControl">
            <mat-option value="phone">{{'PHONE' | translate}}</mat-option>
            <mat-option value="email">{{'ELECTRONIC_EMAIL' | translate}}</mat-option>
          </mat-select>
        </div>

        <div>
          <input *ngIf="methodSelectControl?.value == 'email'" type="email" id="email" name="email"
                 [formControl]="emailControl"
                 class="w-full px-4 py-4 font-[16px] focus:outline-none
                 font-Lato-Light placeholder-[##727272]"
                 placeholder="{{'ELECTRONIC_EMAIL' | translate}}*">
          <div class="text-start">
            <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
              <span *ngIf="emailControl.hasError('required') && emailControl.touched">
                  {{'REQUIRED' | translate}}
              </span>
              <span *ngIf="emailControl.hasError('pattern') && !emailControl.hasError('required') && emailControl.touched">
                    {{'WRONG_FORMAT' | translate}}
              </span>
            </p>
          </div>
        </div>

        <!-- Phone input-->
        <div *ngIf="methodSelectControl?.value == 'phone'" class="relative">
          <div class="absolute inset-y-0 left-0 flex mb-6 items-center">
            <mat-select hideSingleSelectionIndicator class="block bg-white px-4 pl-30"
                        [formControl]="selectControl">
              <mat-select-trigger>
                <div class="w-8 h-8 mr-2">
                  <img src="/assets/flags{{selectControl.value === '+1' ? '/us':'/cu'}}.svg">
                </div>
              </mat-select-trigger>
              <mat-option value="+53">
                <img src="assets/flags/cu.svg" alt="CUB">
                <!--              CUB-->
              </mat-option>
              <mat-option value="+1">
                <img src="assets/flags/us.svg" alt="USA">
                <!--              USA-->
              </mat-option>

            </mat-select>
            <div class="mb-2">
              <span>{{selectControl.value}} </span>
            </div>
          </div>
          <input matInput type="text" id="phone" name="phone"
                 [formControl]="phoneControl"
                 class="w-full  py-4 mb-8 font-[16px] focus:outline-none pl-28
                 font-Lato-Light placeholder-[##727272] text-[##727272]"
                 placeholder="{{'PHONE_NUMBER' | translate}}*">
        </div>
      </form>
      <!-- Submit Button -->
      <button [ngClass]="{'opacity-50' : emailControl.invalid || disableButton || spin, 'cursor-not-allowed' : emailControl.invalid || disableButton || spin}" (click)="sendCode()"
              [disabled]="emailControl.invalid || disableButton || spin"
              class="rounded-full bg-[#FF6D03] py-2 mb-6 w-full
                 flex  justify-center">
        <p *ngIf="!spin" class="font-Lato-Bold text-[19px] text-white">
          {{'SEND_CODE' | translate}}
        </p>
        <div *ngIf="spin" class="flex flex-row justify-center items-center">
          <fa-icon
            class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
            [icon]="faSpinner">
          </fa-icon>
        </div>
      </button>
      <div class="mb-8">
        <span class="text-blue-600 font-Lato-Regular text-[16px] hover:cursor-pointer" (click)="goBack()">
          {{'USE_OTHER_METHOD' | translate}}
        </span>
      </div>
      <footer>
        <div class="my-6">
          <p class="font-Lato-Regular mb-10">
            {{'AGREE' | translate}}
            <a href="https://www.katapulk.com/contents/terms" target="_blank" class="text-blue-600">
              {{'AGREE_SCND' | translate}}
            </a>
          </p>
        </div>
      </footer>
    </div>

  </div>
</div>
