import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {MatIcon} from "@angular/material/icon";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {NavigationService} from "../../../shared/services/navigation.service";
import {AuthService} from "../../../shared/services/auth.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {User} from "../../../shared/models/user.model";
import {response} from "express";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {NotificationService} from "../../../shared/services/notification.service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-personal-information',
  standalone: true,
  imports: [
    TranslateModule,
    MatIcon,
    RouterLink,
    HeaderComponent
  ],
  templateUrl: './personal-information.component.html',
  styleUrl: './personal-information.component.css'
})
export class PersonalInformationComponent implements OnInit, OnDestroy {
  redirect: any;
  name: any;
  email: any;
  user: User = {};
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private authService: AuthService,
  ) {
  }

  async ngOnInit() {
    const paramsMap = this.route.snapshot.queryParamMap;
    if (isAllowed(paramsMap.get('redirect'))) {
      const code = paramsMap.get('code');
      if (code && !this.cookieService.get('firstTime')) {
        await this.authService.getTokensWithCode(code as string).toPromise();
      }
      this.redirect = paramsMap.get('redirect');
      this.cookieService.set('redirect', this.redirect);
      if (this.authService.keyAccessToken) {
        this.initInfo();
      }
    } else {
      this.router.navigate(['/not-found']);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  initInfo() {
    this.authService.getUserInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(resp => {
        this.user = {
          email: resp.data?.email,
          userId: resp.data?.userId,
          firstName: resp.data?.firstname,
          lastName: resp.data?.lastname,
          pendingPhone: resp.data?.phone,
        }
        this.cookieService.set('currentUser', JSON.stringify(this.user));
      });
  }

  navigateTo(url: string, redirect: string) {
    this.navigationService.navigateTo({url: url, redirect: redirect});
  }

  goToApp() {
    this.authService.navigateToApp(this.redirect);
  }
}
