import {Component, ElementRef, OnDestroy, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Location, NgClass, NgIf} from "@angular/common";
import {PasskeyService} from "../../../shared/services/passkey.service";
import {ActivatedRoute} from "@angular/router";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {CodeInputModule} from "../../../shared/components/code-input/code-input.module";
import {TranslateModule} from "@ngx-translate/core";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {NotificationService} from "../../../shared/services/notification.service";
import {NavigationService} from "../../../shared/services/navigation.service";
import {Subject, takeUntil} from "rxjs";
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";

@Component({
  selector: 'app-sign-in-passkey',
  standalone: true,
  imports: [
    FormsModule, NgIf, ReactiveFormsModule, HeaderComponent, CodeInputModule, TranslateModule, NgClass, FaIconComponent, CaptchaComponent
  ],
  templateUrl: './sign-in-passkey.component.html',
  styleUrl: './sign-in-passkey.component.css'
})
export class SignInPasskeyComponent implements OnInit, OnDestroy {
  email = '';
  code = '';
  disableButton = false;
  disableResend = false;
  spin = false;
  token: string | null = null;
  redirect: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  acceptedCaptcha: boolean = false;
  @ViewChild('section') section: ElementRef | undefined;
  @ViewChild(CaptchaComponent) captchaComponent: CaptchaComponent | undefined;

  constructor(
    private passkeyService: PasskeyService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    const paramMap = this.route.snapshot.queryParamMap
    this.email = paramMap.get('email') ?? '';
    this.redirect = paramMap.get('redirect');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  async register() {
    this.disableResend = true;
    this.disableButton = true;
    this.acceptedCaptcha = false;
    this.spin = true;
    if (this.token) {
      try {
        this.passkeyService.getCredentialOptions(this.email, this.code, this.token)
          .pipe(takeUntil(this.destroy$))
          .subscribe(async (result) => {
            if (result.success) {
              await this.registerPasskey(this.email, result.data?.jsonCredentialOptions,result.data.token);
            } else {
              this.notificationService.showAndSubscribe(result.error.message, 'OK');
              this.clearData();
            }
          });
      } catch (error) {
        await this.handleError(error);
        this.clearData();
      } finally {
        this.spin = false;
        this.disableButton = false;
        this.disableResend = false;
      }
    } else {
      this.spin = false;
      this.disableButton = false;
      this.disableResend = false;
    }
  }

  private async registerPasskey(email: string, credentialOptionsJson: any, token : string) {
    this.spin = true;
    try {
      const register = await this.passkeyService.register(email, credentialOptionsJson, token);
      if (register) {
        register.pipe(takeUntil(this.destroy$))
          .subscribe({
            next: async (registerResult) => {
              if (registerResult.success) {
                this.navigateTo('/register-success', this.redirect, '', registerResult.data.code);
              } else {
                this.notificationService.showAndSubscribe(registerResult.error.message, 'OK');
                this.clearData();
              }
            },
            error: async (error) => {
              await this.handleError(error);
              this.clearData();
            }
          });
      }
    } catch (error) {
      await this.handleError(error);
      this.clearData();
    }finally {
      this.clearData();
    }
  }

  codeCompleted(code: string) {
    this.code = code;
  }

  protected readonly faSpinner = faSpinner;

  async resendCode() {
    this.passkeyService.startAttestation(this.email)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async (result) => {
          if (result.success) {
            this.notificationService.showAndSubscribe(result.message, 'OK');
          } else {
            this.notificationService.showAndSubscribe(result.error.message, 'OK');
          }
        }
      });
  }

  navigateTo(url: string, redirect: string, email?: string,code?:string) {
    this.navigationService.navigateTo({
      url: url,
      redirect: redirect,
      email: email,
      code:code
    });
  }

  private async handleError(error: any) {
    if (error instanceof Error) {
      const errorMessage = error.message.split('.')[0];
      switch (error.name) {
        case 'InvalidStateError':
          this.notificationService.showAndSubscribe(errorMessage, 'OK');
          break;
        case 'NotAllowedError':
          this.notificationService.showAndSubscribe(errorMessage, 'OK');
          break;
        default:
          this.notificationService.showAndSubscribe(errorMessage, 'OK');
          break;
      }
    } else {
      this.notificationService.showAndSubscribe('An unknown error occurred. Please try again later.', 'OK');
    }
  }

  clearData() {
    this.code = '';
    this.spin = false;
    this.token = null;
    this.acceptedCaptcha = false;
    this.captchaComponent?.resetCaptcha()
  }

  captchaCapture(token: string) {
    this.token = token;
    this.acceptedCaptcha = true;
    this.cdr.detectChanges();
  }

  goBack() {
    this.location.back();
  }
}
