import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule, Location} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {AuthService} from "../../../shared/services/auth.service";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {NotificationService} from "../../../shared/services/notification.service";
import {NavigationService} from "../../../shared/services/navigation.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {MatDialog} from "@angular/material/dialog";
import {CaptchaModalComponent} from "../../../shared/components/captcha-modal/captcha-modal.component";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-sign-in-code',
  standalone: true,
  imports: [
    CaptchaComponent,
    FaIconComponent,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    CommonModule,
    MatIconModule,
    FormsModule,
    TranslateModule,
    MatCheckboxModule,
    HeaderComponent
  ],
  templateUrl: './sign-in-code.component.html',
  styleUrl: './sign-in-code.component.css'
})
export class SignInCodeComponent implements OnInit, OnDestroy {
  spin: any;
  disableButton = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('section')
  section: any;

  redirect: any;
  faSpinner = faSpinner;
  methodSelectControl: FormControl = new FormControl({value: 'email', disabled: true}, Validators.required);
  emailControl: FormControl = new FormControl(null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
  phoneControl: FormControl = new FormControl(null, Validators.required);
  selectControl: FormControl = new FormControl('+1');

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private dialog: MatDialog,
    private location: Location
  ) {
  }

  ngOnInit() {
    if (typeof window !== 'undefined') {
      const savedEmail = localStorage.getItem('lastEmail');
      if (savedEmail)
        this.emailControl.setValue(savedEmail);
    }
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params) {
          if (isAllowed(params['redirect'])) {
            this.redirect = params['redirect'];
          } else {
            this.router.navigate(['/not-found']);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }


  navigateTo(url: string, redirect: string, code?: string, email?: string) {
    this.navigationService.navigateTo({
      url: url,
      redirect: redirect,
      code: code,
      email: email,
    });
  }

  async sendCode() {
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();
    if(this.emailControl.value)
      localStorage.setItem('lastEmail', this.emailControl.value);
    const req = {
      email: this.emailControl.value,
      captchaToken: token,
    };
    if (token) {
      this.spin = true;
      this.authService.requestOtpSignIn(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe((resp): any => {
          this.spin = false;
          if (resp['success']) {
            this.disableButton = true;
            this.router.navigate(['/check-code'], {
              queryParams: {
                redirect: this.redirect,
                email: this.emailControl.value
              }
            });
            setTimeout(() => {
              this.disableButton = false;
            }, 10000);
          } else {
            if (resp?.error?.responseCode === 'UnconfirmedEmail') {
              this.notificationService.showAndSubscribe(resp?.error?.message,
                'ACCEPT', '', false, '/confirm-register',
                {redirect: this.redirect, email: this.emailControl.value, fromLogin: true});
            } else {
              this.notificationService.showAndSubscribe(resp?.error?.message, 'ACCEPT');
            }
          }
        });
    }
  }

  goBack() {
    this.location.back();
  }
}
