<div class="bg-gray-100 h-full">
  <!-- Header -->
  <app-header>

  </app-header>
  <div #section class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px]">

      <div class="my-8 -mb-0">
        <h3 class="font-Lato-Bold text-[24px] ">
          {{'CHANGE_PASSWORD' | translate}}
        </h3>
      </div>
      <!-- Form -->
      <form class="py-6" [formGroup]="passwordForm">

        <!-- Previous Password  -->
        <app-password-input [passwordControl]="previousPasswordControl" placeholder="{{'PASSWORD' | translate}}" id="password">
        </app-password-input>

        <!-- New Password -->
        <app-password-input [passwordControl]="newPasswordControl" placeholder="{{'NEW_PASSWORD' | translate}}" id="newPassword">
        </app-password-input>

        <!-- Confirm Password Input-->
         <app-password-input [passwordControl]="confirmPasswordControl" placeholder="{{'CONFIRM_PASSWORD' | translate}}" id="confirmPassword">
        </app-password-input>
        <div class="mb-2 text-start" *ngIf="passwordForm.get('newPassword')?.value !== passwordForm.get('confirmPassword')?.value
                    && passwordForm.get('newPassword')?.touched && passwordForm.get('confirmPassword')?.touched">
          <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
            <span>
                {{'MUST_MATCH' | translate}}
            </span>
          </p>
        </div>

        <!-- Submit Button -->
        <button [ngClass]="{ 'opacity-50' : passwordForm.invalid || spin, 'cursor-not-allowed' : passwordForm.invalid || spin }"
                class="mt-6 rounded-full bg-[#FF6D03] py-2 text-[19px] text-white w-full justify-center items-center"
                (click)="onSubmit()" [disabled]= " passwordForm.invalid || spin">
          <p *ngIf="!spin" class="font-Lato-Bold">
            {{'CHANGE_PASSWORD' | translate}}
          </p>
          <div class="flex flex-row justify-center items-center">
            <fa-icon *ngIf="spin"
                     class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
          </div>
        </button>
        <div class="mt-4">
          <span class="text-blue-600 font-Lato-Regular text-[16px] hover:cursor-pointer" (click)="goBack()">
            {{ 'GO_BACK_TO_SETTINGS' | translate }}
          </span>
        </div>
      </form>

    </div>
  </div>
</div>
