import { Component } from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {filter, Subject, takeUntil} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CONFIG} from "../assets/config";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'auth';
  destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly config: { version: string };


  constructor(
      private translateService: TranslateService,
      private router: Router,
      private http: HttpClient
      ) {
    this.config = CONFIG;
    this.verifyVersionChanges();
    this.translateService.setDefaultLang('es');
  }

  verifyVersionChanges() {
    this.router.events.pipe(
        takeUntil(this.destroy$),
        filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      const headers = new HttpHeaders()
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('x-refresh', 'true');
      this.http
          .get<{ version: string }>("/assets/config.json", { headers })
          .pipe(takeUntil(this.destroy$))
          .subscribe(config => {
            if (config.version !== this.config.version) {
              location.reload();
            }
          });
    });
  }
}
