<div class="bg-gray-100 h-full">
  <!-- Header -->
  <app-header></app-header>
  <div #section class="justify-center flex flex-col content-center items-center text-center">
    <div class="w-[333px]">
      <div class="my-8 -mb-0">
        <h3 class="font-Lato-Bold text-[24px]">{{'DEVICE_MANAGEMENT'|translate}}</h3>
      </div>
      <!-- Content -->
      <div class="py-6">
        <!-- If there are biometric devices -->
        <div *ngIf="biometricDevices > 0">
          <p class="text-lg font-Lato-Regular mb-4" *ngIf="language === 'es'">
            Tienes {{ biometricDevices }} dispositivos con acceso biométrico.
          </p>
          <p class="text-lg font-Lato-Regular mb-4" *ngIf="language === 'en'">
            You have {{ biometricDevices }} biometric devices.
          </p>
          <button class="mt-6 rounded-full bg-red-600 py-2 text-[19px] text-white w-full justify-center items-center"
                  (click)="openConfirmationModal()">
            {{'DELETE_ALL_DEVICES' | translate}}
          </button>
        </div>
        <!-- If there are no biometric devices -->
        <div *ngIf="biometricDevices === 0">
          <p class="text-lg font-Lato-Regular text-red-600">
            {{'ACCOUNT_WITHOUT_DEVICES' | translate}}
          </p>
        </div>
        <div class="mt-4">
          <span class="text-blue-600 font-Lato-Regular text-[16px] hover:cursor-pointer" (click)="goBack()">
            {{ 'GO_BACK_TO_SETTINGS' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>


  <!-- Confirmation Modal -->
  <div *ngIf="isModalOpen" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
    <div class="bg-white rounded-lg p-6 w-96">
      <h3 class="font-Lato-Bold text-[20px] mb-4">Confirmación</h3>
      <p class="text-lg font-Lato-Regular mb-4">
        {{'DELETE_PASSKEY_QUESTION' | translate}}
      </p>
      <div class="flex justify-end">
        <button class="mr-2 bg-gray-300 text-gray-800 px-4 py-2 rounded" (click)="closeConfirmationModal()">
          {{'CANCEL' | translate}}
        </button>
        <button class="bg-red-600 text-white px-4 py-2 rounded" (click)="confirmRemoveAllBiometricDevices()">
          {{'CONFIRM' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="fixed inset-0 bg-[rgb(0_0_0_/_32%)] flex items-center justify-center z-50 opacity-100">
    <fa-icon [icon]="faSpinner" class="text-white text-5xl animate-spin"></fa-icon>
  </div>
</div>
