import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, Observable, of, switchMap, tap} from "rxjs";
import {Token} from "../models/token.model";
import {environment} from "../../../environments/environment";
import {CookieService} from "ngx-cookie-service";
import {PhoneNotification} from "../models/phone_notification.model";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private account_resource = '/identity/account';

  private loginResource = '/auth/login2';
  private requestAuthCodeResource = '/getaccesscode'
  private resendEmailResource = '/resendemailtoken';
  private registerResource = '/auth/singup';
  private confirmEmailResource = '/confirmemail';
  private otpLoginResource = '/requestotpsignin';
  private confirmOtpResource = '/confirmotpsignin2';
  private passwordResetResource = '/resendpasswordresettoken';
  private confirmPasswordResetResource = '/confirmselfpasswordreset';
  private changeNameResource = '/changename';
  private updateEmailResource = '/selfchangeemail';
  private confirmNewEmailResource = '/confirmchangeemail';
  private changePasswordResource = '/selfpasswordchange';
  private userInfoResource = '/info';
  private changePhoneResource = '/selfchangephone';
  private confirmChangePhoneResource = '/selfchangephone/confirm';
  private resendChangePhoneResource = '/selfchangephone/resendcode';
  private refreshTokenResource = '/auth/refresh';

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
  ) {
  }

  get keyAccessToken(): string {
    return this.cookieService.get('accessToken') ?? '';
  }

  get currentUser(): any {
    const currentUser = this.cookieService.check('currentUser') ? this.cookieService.get('currentUser') : null;
    return currentUser ? JSON.parse(currentUser) : '';
  }

  get keyRefreshToken(): string {
    return this.cookieService.get('refreshToken') ?? '';
  }

  public login(loginData: any): Observable<Token> {
    const management = this.cookieService.get('management') === 'true';
    const url = environment.services_api_url + environment.v1 + (management ? '/management' : '') + this.account_resource + this.loginResource;

    return this.getToken('POST', url, loginData);
  }

  public register(registerData: any): Observable<any> {
    const url = environment.services_api_url + environment.v1 + this.account_resource + this.registerResource;
    return this.http.post(url, registerData, {headers: this.getHeader(registerData)})
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }


  public confirmEmail(data: any) {
    return this.accessToken('PUT', data, this.confirmEmailResource);
  }

  async logout() {
    if (this.keyAccessToken) {
      await this.signOff();
    }
    this.cookieService.delete('management');
    this.cookieService.delete('accessToken');
    this.cookieService.delete('refreshToken');
    this.cookieService.delete('redirect');
    this.cookieService.delete('currentUser');
    this.cookieService.delete('firstTime');
  }

  async signOff() {
    const management = this.cookieService.get('management') === 'true';
    const url = environment?.services_api_url + environment?.v1 + (management ? '/management' : '') + '/identity/account/auth/signoff';
    await this.http.post(url, {refreshToken: this.keyRefreshToken}).toPromise()
      .then((response) => {
        console.log('SignOff:', response);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  public resendEmailToken(loginData: any) {
    return this.accessToken('PUT', loginData, this.resendEmailResource);
  }

  public requestOtpSignIn(data: any) {
    return this.accessToken('POST', data, this.otpLoginResource);
  }

  public confirmOtpSignIn(data: any) {
    const management = this.cookieService.get('management') === 'true';
    const url = environment.services_api_url + environment.v1 + (management ? '/management' : '') + this.account_resource
      + (management ? '/auth' : '') + this.confirmOtpResource;
    return this.getToken('POST', url, data);
  }

  public passwordResetToken(data: any) {
    return this.accessToken('PUT', data, this.passwordResetResource);
  }

  public confirmPasswordReset(data: any) {
    return this.accessToken('PUT', data, this.confirmPasswordResetResource);
  }

  public changeName(data: any) {
    return this.accessToken('PUT', data, this.changeNameResource);
  }

  public updateEmail(data: any) {
    return this.accessToken('PUT', data, this.updateEmailResource);
  }

  public confirmNewEmail(data: any) {
    return this.accessToken('PUT', data, this.confirmNewEmailResource);
  }

  public changePassword(data: any) {
    return this.accessToken('PUT', data, this.changePasswordResource);
  }

  public getUserInfo(): Observable<any> {
    const url = environment.services_api_url + environment.v1 + this.account_resource + this.userInfoResource;

    const options = {
      headers: this.getHeader(),
    };
    return this.http.get(url, options)
      .pipe(
        tap((resp) => {
          console.log(resp);
        }),
        catchError((err) => {
          return of(err)
        })
      );
  }

  changePhone(data: PhoneNotification) {
    return this.accessToken('PUT', data, this.changePhoneResource);
  }

  verifyChangePhone(data: any): Observable<any> {
    return this.accessToken('PUT', data, this.confirmChangePhoneResource);
  }

  resendChangePhoneCode(data: any): Observable<any> {
    return this.accessToken('PUT', data, this.resendChangePhoneResource);
  }

  public refreshToken(): Observable<boolean> {
    const data = {accessToken: this.keyAccessToken, refreshToken: this.keyRefreshToken};
    const url = environment.services_api_url + environment.v1 + this.account_resource + this.refreshTokenResource;

    return this.http.post(url, data, {headers: this.getHeader(data)})
      .pipe(
        tap((resp) => {
          console.log(resp);
        }),
        switchMap((resp: any) => {
          this._saveToken(resp);
          return of(resp);
        }),
        catchError((err) => {
          return of(err)
        })
      );
  }


  getAuthCode(): Observable<any> {
    const url = environment.services_api_url + environment.v1 + this.account_resource + this.requestAuthCodeResource;

    return this.http.get(url, {headers: this.getHeader()})
      .pipe(
        switchMap((resp: any) => {
          return of(resp);
        }),
        catchError((err) => {
          return of(err)
        })
      );
  }

  public getHeader(body?: any): HttpHeaders {

    let headers = new HttpHeaders({
      'Accept-Language': 'es-ES',
      'X-K-App': '15'
    });

    if (body) {
      headers = headers.set('Content-Type', 'application/json');
    }

    if (this.keyAccessToken) {
      headers = headers.set('Authorization', `Bearer ${this.keyAccessToken}`)
    }

    return headers;
  }

  public accessToken(method: string, loginData: any, resource: string): Observable<any> {
    const url = environment.services_api_url + environment.v1 + this.account_resource + resource;
    return this.getToken(method, url, loginData);
  }

  private getToken(method: string, url: string, body: any): Observable<any> {
    const options =
      {
        headers: this.getHeader(body),
        body: body
      };
    return this.http.request(method, url, options)
      .pipe(
        tap((resp: any) => {
          if (resp?.data?.tokens) {
            this._saveToken(resp);
          }
        }),
        switchMap((token: Token) => {
          return of(token);
        }),
        catchError((err) => {
          return of(err)
        })
      );
  }

  getTokensWithCode(code: string): Observable<any> {
    const url = environment.services_api_url + environment.v1 + '/identity/account/auth/login/redeemcode';

    const headers = new HttpHeaders({
      'accept': 'application/json',
      'x-refresh': 'true',
      'X-K-App': '15'
    });
    return this.http.get(url, {headers: headers, params: {Code: code}}).pipe(
      switchMap((resp: any) => {
        this._saveToken(resp);
        this.cookieService.set('firstTime', "true");
        return of(resp);
      }),
      catchError((err, caught) => {
        return of(err);
      })
    )
  }

  private _saveToken(resp: any) {
    this.cookieService.set('accessToken', resp?.data?.tokens?.accessToken);
    this.cookieService.set('refreshToken', resp?.data?.tokens?.refreshToken);
  }

  async navigateToApp(redirect: string) {
    const redirectURL = new URL(redirect);
    const resp = await this.getAuthCode().toPromise();
    redirectURL.searchParams.append('code', resp?.data?.code);
    await this.logout();
    location.href = redirectURL.href;
  }
}
