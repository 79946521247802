import {Component, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../captcha/captcha.component";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-captcha-modal',
  standalone: true,
  imports: [
    CaptchaComponent
  ],
  templateUrl: './captcha-modal.component.html',
  styleUrl: './captcha-modal.component.css'
})
export class CaptchaModalComponent {
  @ViewChild('section') section: any;

  constructor(
    public dialogRef: MatDialogRef<CaptchaModalComponent>,
  ) {}

  captchaCapture(token: string) {
    setTimeout(() => {
      this.dialogRef.close(token);
    }, 1000);
  }
}
