import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect, MatSelectTrigger} from "@angular/material/select";
import {JsonPipe, NgClass, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {AuthService} from "../../../shared/services/auth.service";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from "@angular/router";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {PasswordInputComponent} from "../../../shared/components/password-input/password-input.component";
import {NavigationService} from "../../../shared/services/navigation.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {NotificationService} from "../../../shared/services/notification.service";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";
import {Country} from "ngx-mat-intl-tel-input/lib/model/country.model";
import {MatDialog} from "@angular/material/dialog";
import {CaptchaModalComponent} from "../../../shared/components/captcha-modal/captcha-modal.component";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    CaptchaComponent,
    FaIconComponent,
    ReactiveFormsModule,
    MatInput,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    NgIf,
    TranslateModule,
    NgClass,
    JsonPipe,
    PasswordInputComponent,
    HeaderComponent,
    NgxMatIntlTelInputComponent
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: FormGroup = this.fb.group(
    {
      name: [null, [Validators.required, Validators.minLength(1)]],
      lastName: [null, [Validators.required, Validators.minLength(1)]],
      email: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: [null, [Validators.required, Validators.minLength(1)]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(6)]],
    },
    {validator: this.passwordMatchValidator}
  );
  token: any;
  spin: boolean = false;
  faSpinner = faSpinner;
  redirect: any;
  dialCode: any;
  countryIso2: any;
  destroy$: Subject<boolean> = new Subject<boolean>();

  get passwordControl(): FormControl {
    return this.registerForm.get('password') as FormControl;
  }

  get confirmPasswordControl(): FormControl {
    return this.registerForm.get('confirmPassword') as FormControl;
  }

  @ViewChild('section')
  section: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
  }


  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
      if (params) {
        if (isAllowed(params['redirect'])) {
          this.redirect = params['redirect'];
        } else {
          this.router.navigate(['/not-found']);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  async onSubmit() {
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      return;
    }
    let aux = this.registerForm.get('phone')?.value ?? '';
    aux = aux.slice(1)
    let phoneNumber = aux?.replace(this.dialCode, '');
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();
    const req = {
      "email": this.registerForm?.value?.email,
      "name": this.registerForm?.value?.name,
      "phone": {
        number: phoneNumber,
        countryIso2: this.countryIso2,
        countryPrefix: this.dialCode
      },
      "lastName": this.registerForm?.value?.lastName,
      "password": this.registerForm?.value?.password,
      "captchaToken": token,
    };
    if (token) {
      this.spin = true;
      this.authService.register(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe(resp => {
        if (resp?.success) {
          this.router.navigate(['/confirm-register'], {queryParams: {redirect: this.redirect, email: req.email}});
        } else {
          this.notificationService.showAndSubscribe(resp?.error?.message, 'ACCEPT');
        }
        this.spin = false;
      });
    }
  }

  passwordMatchValidator(group: FormGroup) {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;

    if (password !== confirmPassword) {
      return {passwordMatch: true};
    }
    return null;
  }

  changePhone(phone: Country) {
    this.countryIso2 = phone.iso2;
    this.dialCode = phone.dialCode;
  }

  navigateTo(url: string, redirect: string) {
    this.navigationService.navigateTo({url: url, redirect: redirect});
  }
}
