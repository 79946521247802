import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {MatIcon} from "@angular/material/icon";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {NavigationService} from "../../../shared/services/navigation.service";
import {AuthService} from "../../../shared/services/auth.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {User} from "../../../shared/models/user.model";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-personal-information',
  standalone: true,
  imports: [
    TranslateModule,
    MatIcon,
    RouterLink,
    HeaderComponent
  ],
  templateUrl: './personal-information.component.html',
  styleUrl: './personal-information.component.css'
})
export class PersonalInformationComponent implements OnInit, OnDestroy {
  redirect: any;
  name: any;
  email: any;
  user: User = {};
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private authService: AuthService,
  ) {
  }

  async ngOnInit() {
    const paramsMap = this.route.snapshot.queryParamMap;
    if (isAllowed(paramsMap.get('redirect'))) {

      const code = paramsMap.get('code');
      if(code !== this.authService.getCode()){
        await this.authService.logout(false);
      }

      this.redirect = paramsMap.get('redirect');
      this.authService.setRedirect(this.redirect);
      if (code && !this.authService.isAuthenticated()) {
        this.authService.setCode(code);
        const result = await this.authService.getTokensWithCode(code as string).toPromise();
        if(result?.error) {
          this.goToApp();
        }
      }
      if (this.authService.keyAccessToken) {
        this.initInfo();
      }
    } else {
      this.router.navigate(['/not-found']);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  initInfo() {
    this.authService.getUserInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(resp => {
        this.user = {
          email: resp.data?.email,
          userId: resp.data?.userId,
          firstName: resp.data?.firstname,
          lastName: resp.data?.lastname,
          pendingPhone: resp.data?.phone,
        }
        this.authService.setCurrentUser(JSON.stringify(this.user));
      });
  }

  navigateTo(url: string, redirect: string) {
    this.navigationService.navigateTo({url: url, redirect: redirect});
  }

  goToAppWithCode() {
    this.authService.navigateToAppWithCode(this.redirect);
  }

  goToApp(){
    this.authService.navigateToApp(this.redirect);
  }
}
