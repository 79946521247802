<div class="flex flex-row gap-2.5 mb-10 justify-around input-group">
   <span *ngFor="let holder of placeholders; index as i"
         [class.code-hidden]="isCodeHidden">
    <input #input class="bg-white w-[46px] h-[46px] text-center text-[#727272]"
           (click)="onClick($event)"
           (paste)="onPaste($event, i)"
           (input)="onInput($event, i)"
           (keydown)="onKeydown($event, i)"
           [type]="inputType"
           [disabled]="disabled"
           [attr.inputmode]="inputMode"
           [attr.autocapitalize]="autocapitalize"
           autocomplete="one-time-code"/>
  </span>
</div>

