<div class="bg-gray-100 h-full">
  <!-- Header -->
  <app-header>

  </app-header>
  <div class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px] ">

      <div class="py-6">
        <div class="my-8 mb-12">
          <h3 class="font-Lato-Bold text-[24px] text-[#252525]">
            {{'CONTACT_INFORMATION' | translate}}
          </h3>
        </div>

        <div class="flex flex-col h-[90px] bg-white justify-between px-6 py-4 mb-4">
          <div class="flex flex-row justify-between">
            <p class="font-Lato-Bold text-[16px] text-[#727272]"> {{'ELECTRONIC_EMAIL' | translate}} </p>
            <mat-icon class="mt-1"  [style.fontSize]=" '16px'" fontIcon="verified_user" [style.color]="'#FF6D03'" ></mat-icon>
          </div>
          <div class="flex flex-row justify-between">
            <p class="font-Lato-Regular text-[16px] text-[#727272]"> {{ user.email }} </p>
            <a class="text-blue-600 underline font-Lato-Regular text-[12px] mt-1 hover:cursor-pointer" (click)="navigateTo('/update-email', redirect)"> {{'UPDATE' | translate}}</a>
          </div>
        </div>
        <div class="flex flex-col h-[90px] bg-white justify-between px-6 py-4 mb-4">
          <div class="flex flex-row justify-between">
            <p class="font-Lato-Bold text-[16px] text-[#727272]"> {{'PHONE' | translate}} </p>
          </div>
          <div class="flex flex-row justify-between">
            <p class="font-Lato-Regular text-[16px] text-[#727272]"> {{ phoneNumber }} </p>
            <a class="text-blue-600 underline font-Lato-Regular text-[12px] mt-1 hover:cursor-pointer" (click)="navigateTo('/update-phone-number', redirect)"> {{'UPDATE' | translate}}</a>
          </div>
        </div>
        <div class="mt-4">
          <span class="text-blue-600 font-Lato-Regular text-[16px] hover:cursor-pointer" (click)="goBack()">
            {{ 'GO_BACK_TO_SETTINGS' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
