<div class="bg-gray-100">
  <!-- Header -->
  <app-header>

  </app-header>
  <div class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px]">

      <div class="py-6">
        <div class="my-8">
          <h3 class="font-Lato-Bold text-[24px] text-[#252525]">
            {{'HELLO' | translate}} {{ user.firstName }}
          </h3>
          <p class="mt-4 mb-6 font-Lato-Regular text-[14px] text-[#252525]">
            {{'PERSONAL_INFORMATION_MESSAGE' | translate}}
          </p>
        </div>

        <hr class="mb-6 border-[#C6C6C6] border-[1px]">

        <div class="flex mat-elevation-z2 h-[80px] bg-white items-center px-4 mb-4">
          <div class="flex flex-row justify-start mr-4">
            <mat-icon style="height: 50px; width: 50px" [style.fontSize]=" '50px'" fontIcon="account_circle"
                      [style.color]="'#FF6D03'"></mat-icon>
          </div>
          <div class="flex flex-col justify-start text-start">
            <p class="text-[18px] text-[#727272] font-Lato-Bold"> {{ user.firstName }} {{ user.lastName }} </p>
            <p class="text-[16px] text-[#727272] font-Lato-Regular"> {{ user.email }} </p>
          </div>
        </div>

        <div
          class="flex flex-row mat-elevation-z2 h-[105px] bg-white items-center px-6 py-4 mb-4 justify-between hover:cursor-pointer"
          (click)="navigateTo('/update-personal-information', redirect)">
          <div class="flex flex-col justify-start text-start">
            <p class="text-[18px] text-[#727272] font-Lato-Bold"> {{'PERSONAL_INFO' | translate}} </p>
            <p class="text-[14px] text-[#727272] font-Lato-Regular"> {{'CHECK_UPDATE_PERSONAL_INFO' | translate}}</p>
          </div>
          <div>
            <mat-icon fontIcon="arrow_forward_ios"></mat-icon>
          </div>
        </div>

        <div
          class="flex flex-row justify-between mat-elevation-z2 h-[105px] bg-white items-center px-6 py-4 mb-4 hover:cursor-pointer"
          (click)="navigateTo('/contact-information', redirect)">
          <div class="flex flex-col justify-start text-start">
            <p class="text-[18px] text-[#727272] font-Lato-Bold"> {{'CONTACT_INFO' | translate}} </p>
            <p class="text-[14px] text-[#727272] font-Lato-Regular"> {{'CONTACT_INFO_MESSAGE' | translate}} </p>
          </div>
          <div>
            <mat-icon fontIcon="arrow_forward_ios"></mat-icon>
          </div>
        </div>

        <div
          class="flex flex-row justify-between mat-elevation-z2 h-[105px] bg-white items-center px-6 py-4 mb-4 hover:cursor-pointer"
          (click)="navigateTo('/change-password', redirect)">
          <div class="flex flex-col justify-start text-start">
            <p class="text-[18px] text-[#727272] font-Lato-Bold"> {{'CHANGE_PASSWORD' | translate}} </p>
            <p class="text-[14px] text-[#727272] font-Lato-Regular"> {{'CHANGE_PASSWORD_MESSAGE' | translate}} </p>
          </div>
          <div>
            <mat-icon fontIcon="arrow_forward_ios"></mat-icon>
          </div>
        </div>
        <div
          class="flex flex-row justify-between mat-elevation-z2 h-[105px] bg-white items-center px-6 py-4 mb-4 hover:cursor-pointer"
          (click)="navigateTo('/devices-access-biometric', redirect)">
          <div class="flex flex-col justify-start text-start">
            <p class="text-[18px] text-[#727272] font-Lato-Bold"> {{'DEVICES_WITH_BIOMETRIC_ACCESS' | translate}} </p>
            <p class="text-[14px] text-[#727272] font-Lato-Regular"> {{'DEVICES_WITH_BIOMETRIC_ACCESS_MESSAGE' | translate}} </p>
          </div>
          <div>
            <mat-icon fontIcon="arrow_forward_ios"></mat-icon>
          </div>
        </div>
        <div
          class="flex flex-row justify-between mat-elevation-z2 h-[61px] bg-white items-center px-6 py-4 mb-12 hover:cursor-pointer"
          (click)="goToAppWithCode()">
          <div class="flex flex-col justify-start text-start">
            <p class="text-[18px] text-[#727272] font-Lato-Bold"> {{'GO_BACK' | translate}}</p>
          </div>
          <div class="mt-1">
            <mat-icon fontIcon="exit_to_app"></mat-icon>
          </div>
        </div>

        <p class="text-[14px] text-[##5D5D5D] font-Lato-Regular"> Puedes
          <span class="text-[14px] text-[##5D5D5D] font-Lato-Bold"> cancelar tu cuenta</span>
          siempre que lo desees.
        </p>
      </div>
    </div>
  </div>
</div>
