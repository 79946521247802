export const environment = {
  production: false,
  name: 'dev_cloud',
  base_route: 'https://dev.api.katapulk.com',
  v1: '/api/v1',
  v2: '/api/v2',

  captcha_key: '3x00000000000000000000FF',
  services_api_url: 'https://devapi-services.katapulk.com',
  retry_on_fail: 2,
  isLocal: false,
  allowedRoutes : [
    new URL('https://dev.katapulk.com'),
    new URL('https://dev-services.katapulk.com'),
    new URL('katapulk://app.katapulk.com/'),
    new URL('https://katapulk.page.link/'),
    new URL('http://localhost:4200'),
    new URL('katapulk://app.katapulk.com'),
    new URL('https://katapulk.page.link'),
  ]
}
