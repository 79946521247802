import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {JsonPipe, NgClass, NgIf} from "@angular/common";
import {faEye, faEyeSlash, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {TranslateModule} from "@ngx-translate/core";
import {AuthService} from "../../../shared/services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {NotificationService} from "../../../shared/services/notification.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {CaptchaModalComponent} from "../../../shared/components/captcha-modal/captcha-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {Subject, takeUntil} from "rxjs";
import {PasswordInputComponent} from "../../../shared/components/password-input/password-input.component";

@Component({
  selector: 'app-confirm-reset-password',
  standalone: true,
  imports: [
    CaptchaComponent,
    FaIconComponent,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    JsonPipe,
    HeaderComponent,
    PasswordInputComponent
  ],
  templateUrl: './confirm-reset-password.component.html',
  styleUrl: './confirm-reset-password.component.css'
})
export class ConfirmResetPasswordComponent implements OnInit, OnDestroy {

  faSpinner = faSpinner;
  showPassword: boolean = true;
  redirect: any;
  email: any;
  token: any;
  spin: any;
  disableResendCode = false;
  disableButton = false
  iconType = faEyeSlash;
  fieldType = 'password';
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('section')
  section: any;

  public formGroup: FormGroup = this.fb.group({
    codeControl: [null, Validators.required],
    passwordControl: [null, [Validators.required, Validators.minLength(6)]],
  });


  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params) {
          if (isAllowed(params['redirect'])) {
            this.redirect = params['redirect'];
            this.email = params['email'];
          } else {
            this.router.navigate(['/not-found']);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }


  async resendEmail() {
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();
    const req = {
      email: this.email,
      captchaToken: token,
    }
    if (token) {
      this.spin = true;
      this.disableButton = true;
      this.authService.passwordResetToken(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe(resp => {
          if (resp?.success) {
            setTimeout(() => {
              this.disableResendCode = false;
            }, 10000);
          }
          this.disableButton = false;
          this.spin = false;
        });
    }
  }

  async onConfirm() {
    let code: string | null | undefined = this.formGroup.get('codeControl')?.value
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();
    const req = {
      "newPassword": this.formGroup.get('passwordControl')?.value,
      "email": this.email.trim(),
      "code": (code ?? '').toUpperCase(),
      "captchaToken": token,
    };
    if (token) {
      this.spin = true;
      this.disableResendCode = true;
      this.disableButton = true;
      this.authService.confirmPasswordReset(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe(resp => {
          if (resp['success']) {
            this.router.navigate(['/sign-in'], {queryParams: {redirect: this.redirect}});
          } else {
            this.notificationService.showAndSubscribe(resp?.error?.message, 'ACCEPT');
          }
          this.spin = false;
          this.disableResendCode = false;
          this.disableButton = false;
        });
    }
  }

  get passwordControl(): FormControl {
    return this.formGroup.get('passwordControl') as FormControl;
  }

}
