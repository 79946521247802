import {Component, OnInit, Output, EventEmitter, Inject, PLATFORM_ID} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {isPlatformBrowser} from '@angular/common';

declare global {
  interface Window {
    _turnstileCb?: any;
    turnstile: any;
  }
}

@Component({
  selector: 'app-captcha',
  standalone: true,
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit {
  @Output() onCaptchaCheck: EventEmitter<string> = new EventEmitter<string>();

  // show_skeleton: boolean = true;

  constructor(@Inject(PLATFORM_ID) private platformID: Object) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      if (window.turnstile) {
        this.render()
      } else {
        window._turnstileCb = () => {
          this.render();
        }
      }
    }

  }

  render() {
    // this.show_skeleton = false;
    window.turnstile.render('#myWidget', {
      sitekey: environment.captcha_key,
      theme: 'light',
      callback: (token: any) => {
        this.sendToPArent(token);
      },
    });
  }

  destroyWidget() {
    // this.show_skeleton = true;
    window.turnstile.remove();
  }

  sendToPArent(token: string) {
    this.onCaptchaCheck.emit(token);
    // this.destroyWidget();
  }

  resetCaptcha() {
    this.destroyWidget();
    this.render();
  }
}
