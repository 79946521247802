<!-- Header -->
<app-header>

</app-header>
<div #section class="bg-gray-100 justify-center flex flex-col content-center items-center text-center">

  <div class="w-[333px]">
    <div class="flex flex-col items-center my-3">
      <h1 class="font-Lato-Bold text-[24px]">
        {{ 'LOG_IN' | translate }}
      </h1>
      <span class="font-Lato-Regular font-[14px] text-[#252525]">
        {{ 'EXPLORE_DISCOVER_AND_BUY_MESSAGE' | translate }}
      </span>
    </div>
    <!-- Form -->
    <form class="py-2" [formGroup]="loginForm">
      <!-- Email Input -->
      <div class="mb-4">
        <input type="email" id="email" name="email"
               formControlName="email"
               class="w-full px-4 py-4 focus:outline-none
               font-Lato-Light placeholder-[##727272]"
               placeholder="{{'ELECTRONIC_EMAIL' | translate}}*">
      </div>
      <!-- Password Input -->
      <app-password-input (keydown.enter)="onSubmit()" [passwordControl]="passwordControl"
                          placeholder="{{'PASSWORD' | translate}}" id="password">
      </app-password-input>
    </form>
    <button (click)="navigateTo('/recover-password', redirect)"
            class="text-start w-full hover:cursor-pointer">
        <span class="text-blue-600 font-Lato-Regular">
          {{ 'FORGOT_YOUR_PASSWORD' | translate }}
        </span>
    </button>
    <!-- Submit Button -->
    <button [ngClass]="{'cursor-not-allowed': spin || loginForm.invalid, 'opacity-50': spin || loginForm.invalid}"
            [disabled]="spin || loginForm.invalid" (click)="onSubmit()"
            class="my-4 rounded-full bg-[#FF6D03] py-2 text-[19px] text-white w-full
            flex justify-center">
        <span *ngIf="!spin" class="font-Lato-Bold">
          {{ 'LOG_IN' | translate }}
        </span>
      <fa-icon *ngIf="spin"
               class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
               [icon]="faSpinner">
      </fa-icon>
    </button>
    <div>
        <span class="text-blue-600 font-Lato-Regular text-[16px] hover:cursor-pointer" (click)="goBack()">
          {{'USE_OTHER_METHOD' | translate}}
        </span>
    </div>
    <div class="py-4">
      <h3 class="font-Lato-Bold text-[24px] mb-4">
        {{ 'ARE_YOU_NEW' | translate }}
      </h3>

      <p class="mb-5 font-Lato-Regular text-[14px]">
        {{ 'CREATE_ACCOUNT_MESSAGE' | translate }}
      </p>

      <div (click)="navigateTo('/register', redirect)"
           class=" rounded-full border-[#FF6D03] border-2 py-2 mb-5 w-full flex  justify-center hover:cursor-pointer">
        <p class="font-Lato-Bold text-[19px] text-[#FF6D03]">
          {{ 'CREATE_ACCOUNT' | translate }}
        </p>
      </div>

      <p class="font-Lato-Regular mb-5">
        {{ 'AGREE' | translate }}
        <a href="#" class="text-blue-600">
          {{ 'AGREE_SCND' | translate }}
        </a>
      </p>
    </div>
  </div>
</div>
