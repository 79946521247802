<div class="relative mb-4">
  <input [type]="fieldType" name="password"
         [formControl]="passwordControl"
         class="w-full px-4 py-4 focus:outline-none
             font-Lato-Light " [placeholder]="placeholder + '*'">
  <fa-icon (click)="showHidePassword()"
           class="absolute right-2 py-4 rounded-full w-7 h-7 cursor-pointer"
           [icon]="iconType">
  </fa-icon>
  <div class="text-start">
    <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
            <span
              *ngIf="passwordControl.hasError('required') && passwordControl.touched">
                {{'REQUIRED' | translate}}
            </span>
      <span *ngIf="passwordControl.hasError('minlength') && !passwordControl.hasError('required') && passwordControl.touched">
                {{'MINIMUN_CHAR' | translate}}
            </span>
    </p>
  </div>
</div>
