import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import {enableProdMode} from "@angular/core";
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import * as SentryReplay from "@sentry/angular-ivy";

if (environment.production) {
  enableProdMode();
}

if (!environment.isLocal) {
  Sentry.init({
    dsn: 'https://04e3b71bef64cd639d933e342fe5c6d9@o4504119300063232.ingest.us.sentry.io/4507407959588864',
    integrations: [
      SentryReplay.replayIntegration({
        maskAllInputs: true,
        blockAllMedia: true,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: environment.name,
    ignoreErrors: [
      "TurnstileError",
      "[Cloudflare Turnstile]",
      "Can't find variable: _AutofillCallbackHandler",
      "ReferenceError global code"
    ],
  });
}
bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
