import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Location, NgClass, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {AuthService} from "../../../shared/services/auth.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect, MatSelectTrigger} from "@angular/material/select";
import {PasswordInputComponent} from "../../../shared/components/password-input/password-input.component";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {Subject, takeUntil} from "rxjs";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    CaptchaComponent,
    FaIconComponent,
    FormsModule,
    MatInput,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    PasswordInputComponent,
    HeaderComponent
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  faSpinner = faSpinner;
  redirect: any;
  email: any;
  token: any;
  spin: any;
  disableResendCode: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();


  @ViewChild('section')
  section: any;

  passwordForm: FormGroup = this.fb.group(
    {
      oldPassword: [null, [Validators.required, Validators.minLength(6)]],
      newPassword: [null, [Validators.required, Validators.minLength(6)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(6)]],
    },
    {validator: this.passwordMatchValidator}
  );

  get previousPasswordControl() {
    return this.passwordForm.get('oldPassword') as FormControl;
  }

  get newPasswordControl() {
    return this.passwordForm.get('newPassword') as FormControl;
  }

  get confirmPasswordControl() {
    return this.passwordForm.get('confirmPassword') as FormControl;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private notificationServices: NotificationService,
    private fb: FormBuilder,
    private cookieService: CookieService,
    private location: Location
  ) {
  }

  async ngOnInit() {
    const paramsMap = this.route.snapshot.queryParamMap;
    const code = paramsMap.get('code');
    if(code !== this.authService.getCode())
      await this.authService.logout(false);
    if (isAllowed(paramsMap.get('redirect'))) {
      if (code && !this.authService.isAuthenticated()) {
        this.authService.setCode(code);
        const result = await this.authService.getTokensWithCode(paramsMap.get('code') as string).toPromise();
        if(result?.error){
          this.goBack();
        }
      }
      this.redirect = paramsMap.get('redirect');
      this.cookieService.set('redirect', this.redirect);
    } else {
      this.router.navigate(['/not-found']);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  passwordMatchValidator(group: FormGroup) {
    const newPassword = group.get('newPassword')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;

    if (newPassword !== confirmPassword) {
      return {passwordMatch: true};
    }
    return null;
  }

  onSubmit() {
    const req = {
      oldPassword: this.passwordForm.get('oldPassword')?.value,
      newPassword: this.passwordForm.get('newPassword')?.value,
    }
    this.authService.changePassword(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(resp => {
        if (resp?.error) {
          this.notificationServices.showAndSubscribe(resp?.error?.message, 'ACCEPT');
        } else {
          this.goBack();
        }
      });
  }

  goBack() {
    this.location.back();
  }
}
