import { Routes } from '@angular/router';
import {SignInComponent} from "./components/auth/sign-in/sign-in.component";
import {SignInCodeComponent} from "./components/auth/sign-in-code/sign-in-code.component";
import {CheckCodeComponent} from "./components/auth/check-code/check-code.component";
import {RegisterComponent} from "./components/auth/register/register.component";
import {ConfirmRegisterComponent} from "./components/auth/confirm-register/confirm-register.component";
import {RegisterSuccessComponent} from "./components/auth/register-success/register-success.component";
import {RecoverPasswordComponent} from "./components/auth/recover-password/recover-password.component";
import {LoginSuccessComponent} from "./components/auth/login-success/login-success.component";
import {NotFoundComponent} from "./shared/components/not-found/not-found.component";
import {PersonalInformationComponent} from "./components/profile/personal-information/personal-information.component";
import {
  UpdatePersonalInformationComponent
} from "./components/profile/update-personal-information/update-personal-information.component";
import {ContactInformationComponent} from "./components/profile/contact-information/contact-information.component";
import {ConfirmResetPasswordComponent} from "./components/auth/confirm-reset-password/confirm-reset-password.component";
import {UpdateEmailComponent} from "./components/profile/update-email/update-email.component";
import {ConfirmChangeEmailComponent} from "./components/profile/confirm-change-email/confirm-change-email.component";
import {UpdatePhoneNumberComponent} from "./components/profile/update-phone-number/update-phone-number.component";
import {ChangePasswordComponent} from "./components/profile/change-password/change-password.component";
import {ConfirmPhoneComponent} from "./components/profile/confirm-phone/confirm-phone.component";
import {VerifyChangePhoneComponent} from "./components/profile/verify-change-phone/verify-change-phone.component";
import {SignInPasswordComponent} from "./components/auth/sign-in-password/sign-in-password.component";
import {SignInPasskeyComponent} from "./components/auth/sign-in-passkey/sign-in-passkey.component";
import {DevicesAccessBiometricComponent} from "./components/profile/devices-access-biometric/devices-access-biometric.component";

export const routes: Routes = [
  {path: 'sign-in', component: SignInComponent},
  {path: 'sign-in-code', component: SignInCodeComponent},
  {path: 'sign-in-password', component: SignInPasswordComponent},
  {path: 'sign-in-passkey', component: SignInPasskeyComponent},
  {path: 'check-code', component: CheckCodeComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'confirm-register', component: ConfirmRegisterComponent},
  {path: 'register-success', component: RegisterSuccessComponent},
  {path: 'recover-password', component: RecoverPasswordComponent},
  {path: 'confirm-reset-password', component: ConfirmResetPasswordComponent},
  {path: 'login-success', component: LoginSuccessComponent},
  {path: 'personal-information', component: PersonalInformationComponent},
  {path: 'update-personal-information', component: UpdatePersonalInformationComponent},
  {path: 'contact-information', component: ContactInformationComponent},
  {path: 'update-email', component: UpdateEmailComponent},
  {path: 'confirm-update-email', component: ConfirmChangeEmailComponent},
  {path: 'update-phone-number', component: UpdatePhoneNumberComponent},
  {path: 'change-password', component: ChangePasswordComponent},
  {path: 'confirm-phone', component: ConfirmPhoneComponent},
  {path: 'verify-change-phone', component: VerifyChangePhoneComponent},
  {path: 'devices-access-biometric', component: DevicesAccessBiometricComponent},
  {path: 'not-found', component: NotFoundComponent},
  {path: '**', redirectTo: 'not-found'}
];
