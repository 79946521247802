<div class="bg-gray-100 h-full">
  <!-- Header -->
  <app-header>

  </app-header>
  <div #section class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px]">

      <div class="my-8">
        <h3 class="font-Lato-Bold text-[24px]">
          {{'CONFIRM_RECOVER_PASSWORD' | translate}}
        </h3>
        <p class="font-Lato-Regular text-[14px]">
          {{'EMAIL_VERIFICATION_CODE_RECOVER_PASSWORD' | translate}}
        </p>
      </div>
      <!-- Form -->
      <form [formGroup]="formGroup">
        <div class="text-start mb-4">
          <input type="text" id="code" name="code"
                 class="w-full px-4 py-4  font-[16px] focus:outline-none
                 font-Lato-Light placeholder-[##727272]"
                 formControlName="codeControl"
                 placeholder="{{'CONFIRMATION_CODE' | translate}}*">
          <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
                <span
                  *ngIf="formGroup.get('codeControl')?.hasError('required') && formGroup.get('codeControl')?.touched">
                    {{'REQUIRED' | translate}}
                </span>
          </p>
        </div>

        <div class="relative text-start mb-8">
          <input [type]="fieldType" id="newPassword" name="newPassword"
                 class="w-full px-4 py-4  font-[16px] focus:outline-none
                 font-Lato-Light placeholder-[##727272]"
                 (keyup.enter)="onConfirm()"
                 formControlName="passwordControl"
                 placeholder="{{'NEW_PASSWORD' | translate}}*">
          <fa-icon (click)="showHidePassword()"
                   class="absolute right-2 py-4 rounded-full w-7 h-7 cursor-pointer"
                   [icon]="iconType">
          </fa-icon>
          <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
                <span
                  *ngIf="formGroup.get('passwordControl')?.hasError('required') && formGroup.get('passwordControl')?.touched">
                    {{'REQUIRED' | translate}}
                </span>
          </p>
        </div>

      </form>
      <!-- Submit Button -->
      <button [ngClass]="{'opacity-50' : formGroup.invalid || spin || disableButton,
          'cursor-not-allowed' : formGroup.invalid || spin || disableButton}"
              (click)="onConfirm()"
              class="mt-8 rounded-full bg-[#FF6D03] py-2 text-[19px] text-white mb-6 w-full"
              [disabled]="formGroup.invalid || spin || disableButton">
        <p *ngIf="!spin" class="font-Lato-Bold text-[19px]">
          {{'CONFIRM' | translate}}
        </p>
        <div class="flex flex-row justify-center items-center">
          <fa-icon *ngIf="spin"
                   class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                   [icon]="faSpinner">
          </fa-icon>
        </div>
      </button>
      <div class="mb-6">
        <button
          [ngClass]="{ 'cursor-not-allowed': spin || disableResendCode, 'text-gray-300' : spin || disableResendCode }"
          [disabled]="spin || disableResendCode"
          class="text-blue-600  font-Lato-Regular
              text-[16px]" (click)="resendEmail()">
          {{'RESEND_CODE' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
