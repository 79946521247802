import {Component, OnInit} from '@angular/core';
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {NgIf, Location} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {PasskeyService} from "../../../shared/services/passkey.service";
import {Subject, takeUntil} from "rxjs";
import {NotificationService} from "../../../shared/services/notification.service";
import {CookieService} from "ngx-cookie-service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";

@Component({
  selector: 'app-devices-access-biometric',
  standalone: true,
    imports: [
        HeaderComponent,
        NgIf,
        TranslateModule,
        FaIconComponent
    ],
  templateUrl: './devices-access-biometric.component.html',
  styleUrl: './devices-access-biometric.component.css'
})
export class DevicesAccessBiometricComponent implements OnInit {
  biometricDevices: number = 0;
  isModalOpen = false;
  email: string = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  user: any;
  faSpinner = faSpinner;
  loading:boolean = false;
  language: string = '';

  constructor(private location: Location,
              private passkeyService: PasskeyService,
              private cookieService: CookieService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.loading = true;
    const currentUser = this.cookieService.get('currentUser');
    this.user = JSON.parse(currentUser);
    this.email = this.user.email;
    this.language = this.getBrowseeLanguage();
    try{
    this.passkeyService.getPasskeysByEmail(this.email)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        if (result.success) {
          this.biometricDevices = result.data.userPasskeysCount;
          this.loading = false;
        }else{
          this.notificationService.showAndSubscribe(result.error.message, 'OK');
          this.loading = false;
        }
      });}catch(e){
      this.notificationService.showAndSubscribe('Error fetching biometric devices', 'OK');
      this.loading = false;
    }
  }

  openConfirmationModal() {
    this.isModalOpen = true;
  }

  closeConfirmationModal() {
    this.isModalOpen = false;
  }

  confirmRemoveAllBiometricDevices() {
    this.passkeyService.deleteAllPasskeysByEmail(this.email)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        if (result.success) {
          this.biometricDevices = 0;
          this.notificationService.showAndSubscribe('All biometric devices have been removed.', 'OK');
        } else {
          this.notificationService.showAndSubscribe(result.error.message, 'OK');
        }
      });
    this.isModalOpen = false;
  }

  goBack() {
    this.location.back();
  }
  private getBrowseeLanguage() {
    const language = navigator.language || navigator.languages[0];
    return language.startsWith('es') ? 'es' : 'en';
  }
}
