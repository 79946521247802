import {Component, OnDestroy, OnInit} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect, MatSelectTrigger} from "@angular/material/select";
import {NgIf} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {AuthService} from "../../../shared/services/auth.service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-register-success',
  standalone: true,
  imports: [
    CaptchaComponent,
    MatInput,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    HeaderComponent
  ],
  templateUrl: './register-success.component.html',
  styleUrl: './register-success.component.css'
})
export class RegisterSuccessComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  redirect: any;
  code: any;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
      this.redirect = params['redirect'];
      this.code = params['code'];
        setTimeout(() => {
          this.onSubmit();
        }, 3000)
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  onSubmit() {
    this.authService.logout();
    const redirect: URL = new URL(this.redirect);
    redirect.searchParams.append('code', this.code);
    window.location.replace(redirect.href.toString());
  }
}
