import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private router: Router,
  ) { }


  navigateTo({url, redirect, email, code,management} : {url: string, redirect?: string, code?: string, email?: string,management?: string}) {
    this.router.navigate(
      [url],
      {
        queryParams:
          {
            redirect: redirect,
            code: code,
            email: email,
            management: management
          }
      }
    );
  }
}

