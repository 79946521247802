import {Injectable} from '@angular/core';
import {NotificationModalComponent} from "../components/notification-modal/notification-modal.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private dialog: MatDialog,
  ) {
  }


  showAndSubscribe(msg: string, btn_ok_text: string, btn_cancel_txt?: string, disableClose: boolean = false, redirect?: string, queryParams?: any) {
    return this.dialog.open(NotificationModalComponent, {
      // backdropClass: '',
      disableClose: disableClose,
      width: 'auto',
      data: {
        msg: msg,
        btn_ok_text: btn_ok_text,
        btn_cancel_txt: btn_cancel_txt,
        redirect: redirect,
        queryParams: queryParams
      },
    })
  }
  showAndConfirm(msg: string, btn_ok_text: string, btn_cancel_txt?: string, disableClose: boolean = false, redirect?: string, queryParams?: any): Promise<boolean> {
    const dialogRef = this.dialog.open(NotificationModalComponent, {
      disableClose: disableClose,
      width: 'auto',
      data: {
        msg: msg,
        btn_ok_text: btn_ok_text,
        btn_cancel_txt: btn_cancel_txt,
        redirect: redirect,
        queryParams: queryParams
      },
    });

    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }
}
