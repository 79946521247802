<div class="bg-gray-100 h-full">
  <!-- Header -->
  <app-header>

  </app-header>
  <div #section class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px]">

      <div class="my-8">
        <h3 class="font-Lato-Bold text-3xl">
          {{'RECOVER_PASSWORD' | translate}}
        </h3>
        <p class="mt-4 font-Lato-Regular flex flex-col text-center justify-center">
          {{'EMAIL_RECOVER_MESSAGE' | translate}}
        </p>
      </div>
      <!-- Form -->
      <form>
        <!-- Select for code -->
<!--        <div class="relative mb-4 text-start">-->
<!--          <mat-select hideSingleSelectionIndicator class="w-full text-[#727272] px-6 py-4 focus:outline-none bg-white-->
<!--               font-Lato-Light text-[##727272]" placeholder="{{'SEND_CODE_METHOD' | translate}}*"-->
<!--                      [formControl]="methodSelectControl">-->
<!--            <mat-option value="phone">{{'PHONE' | translate}}</mat-option>-->
<!--            <mat-option value="email">{{'ELECTRONIC_EMAIL' | translate}}</mat-option>-->
<!--          </mat-select>-->
<!--        </div>-->

        <input type="email" id="email" name="email"
               [formControl]="emailControl"
               class="w-full px-4 py-4 mb-2 font-[16px] focus:outline-none
               font-Lato-Light placeholder-[##727272]"
               placeholder="{{'ELECTRONIC_EMAIL' | translate}}*">

        <!-- Phone input-->
<!--        <div *ngIf="methodSelectControl?.value == 'phone'" class="relative">-->
<!--          <div class="absolute inset-y-0 left-0 flex mb-6 items-center">-->
<!--            <mat-select hideSingleSelectionIndicator class="block bg-white px-4 pl-30"-->
<!--                        [formControl]="phoneControl">-->
<!--              <mat-select-trigger>-->
<!--                <div class="w-8 h-8 mr-2">-->
<!--                  <img src="/assets/flags{{phoneControl.value === '+1' ? '/us':'/cu'}}.svg">-->
<!--                </div>-->
<!--              </mat-select-trigger>-->
<!--              <mat-option value="+53">-->
<!--                <img src="assets/flags/cu.svg" alt="CUB">-->
<!--                &lt;!&ndash;              CUB&ndash;&gt;-->
<!--              </mat-option>-->
<!--              <mat-option value="+1">-->
<!--                <img src="assets/flags/us.svg" alt="USA">-->
<!--                &lt;!&ndash;              USA&ndash;&gt;-->
<!--              </mat-option>-->

<!--            </mat-select>-->
<!--            <div class="mb-2">-->
<!--              <span>{{phoneControl.value}} </span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <input matInput type="text" id="phone" name="phone"-->
<!--                 formControlName="phone"-->
<!--                 class="w-full py-4 mb-8 font-[16px] focus:outline-none pl-28-->
<!--               font-Lato-Light placeholder-[##727272] text-[##727272]"-->
<!--                 placeholder="{{'PHONE_NUMBER' | translate}}*">-->
<!--        </div>-->
      </form>


      <!-- Submit Button -->
      <button
        [ngClass]="{'opacity-50' : emailControl.invalid || disableButton || spin, 'cursor-not-allowed' : emailControl.invalid || disableButton || spin }"
        [disabled]="emailControl.invalid || disableButton || spin"
        (click)="onPasswordReset()"
        class="mt-6 rounded-full bg-[#FF6D03] py-2 text-[19px] text-white w-full">
        <p *ngIf="!spin" class="font-Lato-Bold">
          {{'SEND_CODE' | translate}}
        </p>
        <div *ngIf="spin" class="flex flex-row justify-center items-center">
          <fa-icon
            class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
            [icon]="faSpinner">
          </fa-icon>
        </div>
      </button>
    </div>

  </div>

</div>
