import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {NavigationService} from "../../../shared/services/navigation.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {User} from "../../../shared/models/user.model";
import {AsYouType, parsePhoneNumber} from "libphonenumber-js";
import {Subject, takeUntil} from "rxjs";
import {Location} from "@angular/common";

@Component({
  selector: 'app-contact-information',
  standalone: true,
  imports: [
    MatIcon,
    TranslateModule,
    RouterLink,
    HeaderComponent
  ],
  templateUrl: './contact-information.component.html',
  styleUrl: './contact-information.component.css'
})
export class ContactInformationComponent implements OnInit, OnDestroy {
  redirect: any;
  user: User = {};
  phoneNumber: any;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private cookieService: CookieService,
    private location: Location
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params) {
          this.redirect = params['redirect'];
        }
      });
    this.initInfo();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  private initInfo() {
    const currentUser = this.cookieService.get('currentUser');
    this.user = JSON.parse(currentUser);
    this.phoneNumber = new AsYouType('US').input(`+${this.user.pendingPhone.countryPrefix}${this.user.pendingPhone.number}`);
  }

  navigateTo(url: string, redirect: string) {
    this.router.navigate([url], {queryParams: {redirect}, replaceUrl: true});
  }

  goBack() {
    this.location.back();
  }
}
