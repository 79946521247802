import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {AuthService} from "../../../shared/services/auth.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {MatDialog} from "@angular/material/dialog";
import {CaptchaModalComponent} from "../../../shared/components/captcha-modal/captcha-modal.component";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-confirm-change-email',
  standalone: true,
  imports: [
    CaptchaComponent,
    FaIconComponent,
    FormsModule,
    NgIf,
    TranslateModule,
    ReactiveFormsModule,
    NgClass,
    HeaderComponent
  ],
  templateUrl: './confirm-change-email.component.html',
  styleUrl: './confirm-change-email.component.css'
})
export class ConfirmChangeEmailComponent implements OnInit, OnDestroy {
  faSpinner = faSpinner;
  redirect: any;
  email: any;
  token: any;
  spin: any;
  disableResendCode: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();


  @ViewChild('section')
  section: any;

  inputControl = new FormControl(null, Validators.required);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
      if (params) {
        this.redirect = params['redirect'];
        this.email = params['email'];
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  async onConfirm() {
    const code = this.inputControl?.value;
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();
    const req = {
      "code": (code ?? '').toUpperCase(),
      "captchaToken": token,
    }
    if (token) {
      this.spin = true;
      this.authService.confirmNewEmail(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe(resp => {
        this.spin = false;
        if (resp?.data) {
          this.router.navigate(['/personal-information'], {
            queryParams: {
              redirect: this.redirect,
              accessToken: resp?.data?.tokens?.accessToken,
            }
          });
        } else {
          this.notificationService.showAndSubscribe(resp?.error?.message,
            'ACCEPT',);
        }
      });
    }
  }

  resendEmail() {
    const req = {
      "newEmail": this.email
    }
    this.disableResendCode = true;
    this.spin = true
    this.authService.updateEmail(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(resp => {
      this.spin = false;
      if (resp?.data) {
        setTimeout(() => {
          this.disableResendCode = false;
        }, 10000);
      } else {
        this.notificationService.showAndSubscribe(resp?.error?.message,
          'ACCEPT',);
      }
    });
  }
}
