import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Location, NgClass, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {AuthService} from "../../../shared/services/auth.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {CookieService} from "ngx-cookie-service";
import {User} from "../../../shared/models/user.model";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-update-email',
  standalone: true,
  imports: [
    CaptchaComponent,
    FaIconComponent,
    FormsModule,
    NgIf,
    TranslateModule,
    ReactiveFormsModule,
    NgClass,
    HeaderComponent
  ],
  templateUrl: './update-email.component.html',
  styleUrl: './update-email.component.css'
})
export class UpdateEmailComponent implements OnInit, OnDestroy {
  faSpinner = faSpinner;
  redirect: any;
  email: any;
  spin: any;
  user: User = {};
  disableResendCode: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();


  @ViewChild('section')
  section: any;

  emailControl = new FormControl('', Validators.required);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private cookieService: CookieService,
    private location: Location
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
      if (params) {
        this.redirect = params['redirect'];
      }
    });
    this.initInfo();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  private initInfo() {
    const currentUser = this.cookieService.get('currentUser');
    this.user = JSON.parse( currentUser );
    const email = this.user?.email ?? '';
    this.emailControl.setValue( email );
  }

  sendCode() {
    const req = {
      "newEmail": this.emailControl?.value
    }
    this.spin = true;
    this.authService.updateEmail(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(resp => {
      this.spin = false;
      if (resp?.data) {
        this.router.navigate(['/confirm-update-email'], {
          queryParams: {
            redirect: this.redirect,
            email: this.emailControl?.value
          },
          replaceUrl: true
        });
      } else {
        this.notificationService.showAndSubscribe(resp?.error?.message, 'ACCEPT',);
      }
    });
  }
  goBack() {
    this.location.back();
  }
}
