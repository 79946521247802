import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {CommonModule, JsonPipe, Location, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {AuthService} from "../../../shared/services/auth.service";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../../shared/services/notification.service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {CodeInputModule} from "../../../shared/components/code-input/code-input.module";
import {NavigationService} from "../../../shared/services/navigation.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {CookieService} from "ngx-cookie-service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-verify-change-phone',
  standalone: true,
  imports: [
    CaptchaComponent,
    MatInput,
    MatOption,
    MatSelect,
    NgIf,
    ReactiveFormsModule,
    JsonPipe,
    TranslateModule,
    CommonModule,
    FaIconComponent,
    FormsModule,
    CodeInputModule,
    HeaderComponent
  ],
  templateUrl: './verify-change-phone.component.html',
  styleUrl: './verify-change-phone.component.css'
})
export class VerifyChangePhoneComponent implements OnInit, OnDestroy {
  redirect: any;
  fromLogin: any;
  fromContactInfo: boolean = false;
  notificationType: any;
  email: any;
  token: any;
  code: any;
  isHuman = false;
  disableResend = false;
  disableButton = false;
  spin = false;
  faSpinner = faSpinner;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('section') section: any;


  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private cookieService: CookieService,
    private location: Location
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
      if (params['redirect']) {
        this.redirect = params['redirect'];
        this.fromLogin = params['fromLogin'] === "true";
        this.notificationType = params['notificationType'];
        this.fromContactInfo = params['fromContactInfo'];
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  checkCode() {
    this.spin = true;
    this.disableButton = true;

    const code = this.code.toUpperCase();

    const req = {
      code: code
    }
    this.authService.verifyChangePhone(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(resp => {
      if (resp?.success) {
        this.disableButton = true;
        if (this.fromContactInfo) {
          this.location.back();
        } else {
          this.authService.getAuthCode()
            .pipe(takeUntil(this.destroy$))
            .subscribe(authCode => {
            if (this.fromLogin) {
              this.router.navigate(['/login-success'], {
                queryParams: {
                  redirect: this.redirect,
                  code: authCode?.data?.code,
                }, replaceUrl: true
              });
            } else {
              this.router.navigate(
                ['/register-success'],
                {
                  queryParams: {
                    redirect: this.redirect,
                    code: authCode?.data?.code,
                  }, replaceUrl: true
                }
              );
            }
            this.spin = false;
          });
        }
      } else {
        this.notificationService.showAndSubscribe(resp?.error?.message, 'ACCEPT');
        this.disableResend = false;
        this.disableButton = false;
        this.spin = false;
      }
    });
  }

  codeCompleted(code: string) {
    this.code = code;
  }

  resendCode() {
    this.spin = true;
    this.disableResend = true;
    const req = {notificationType: parseInt(this.notificationType)};
    this.authService.resendChangePhoneCode(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(resp => {
      this.spin = false;
      setTimeout(() => {
        this.disableResend = false;
      }, 10000);

    });
  }
}
