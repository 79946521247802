import {Injectable} from '@angular/core';
import {catchError, Observable, of, Subject, switchMap, tap} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {PublicKeyCredentialCreationOptionsJSON, PublicKeyCredentialRequestOptionsJSON} from "@simplewebauthn/types";
import {Base64UrlService} from "./base64-url.service";
import {environment} from "../../../environments/environment";
import {Token} from "../models/token.model";
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class PasskeyService {
  private account_resource = '/identity/account/passkey';
  private get_assertion_options_resource = '/getAssertionOptions';
  private get_credential_options_resource = '/getCredentialOptions';
  private assertion_resource = '/assertUserPasskey';
  private validation_resource = '/validateUserPasskey';
  private start_attestation_resource = '/startAttestation';
  private register_resource = '/createUserPasskey';
  private get_passkeys_by_email_resource = '/getPasskeysByEmail';
  private delete_all_passkeys_by_email_resource = '/deleteAllPasskeysByEmail';
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient,
              private base64UrlService: Base64UrlService,
              private cookieService: CookieService) {
  }

  get keyAccessToken(): string {
    return this.cookieService.get('accessToken') ?? '';
  }

  get currentUser(): any {
    const currentUser = this.cookieService.check('currentUser') ? this.cookieService.get('currentUser') : null;
    return currentUser ? JSON.parse(currentUser) : '';
  }

  private _saveToken(resp: any) {
    this.cookieService.set('accessToken', resp?.data?.tokens?.accessToken);
    this.cookieService.set('refreshToken', resp?.data?.tokens?.refreshToken);
  }

  private getHeader(body?: any): HttpHeaders {

    let headers = new HttpHeaders({
      'Accept-Language': 'es-ES',
      'X-K-App': '15'
    });

    if (body) {
      headers = headers.set('Content-Type', 'application/json');
    }

    if (this.keyAccessToken) {
      headers = headers.set('Authorization', `Bearer ${this.keyAccessToken}`)
    }

    return headers;
  }

  private getResponse(method: string, url: string, body: any): Observable<any> {
    const options =
      {
        headers: this.getHeader(body),
        body: body
      };
    return this.http.request(method, url, options)
      .pipe(
        tap((resp: any) => {
          if (resp?.data?.tokens) {
            this._saveToken(resp);
          }
        }),
        switchMap((token: Token) => {
          return of(token);
        }),
        catchError((err) => {
          return of(err)
        })
      );
  }

  private isWebAuthnPossible() {
    return !!window.PublicKeyCredential;
  }

  public getPasskeysByEmail(email: string) {
    const req = {UserEmail: email};
    const passkeysUrl = environment.services_api_url + environment.v1 + this.account_resource + this.get_passkeys_by_email_resource;
    return this.getResponse('POST', passkeysUrl, req);
  }

  public deleteAllPasskeysByEmail(email: string) {
    const req = {UserEmail: email};
    const passkeysUrl = environment.services_api_url + environment.v1 + this.account_resource + this.delete_all_passkeys_by_email_resource;
    return this.getResponse('DELETE', passkeysUrl, req);
  }

  public getCredentialOptions(userName: string, code: string, token: string): Observable<any> {
    const user = {UserEmail: userName, Code: code, CaptchaToken: token};
    const assertionOptionsUrl = environment.services_api_url + environment.v1 + this.account_resource + this.get_credential_options_resource;
    return this.getResponse('POST', assertionOptionsUrl, user);
  }

  private async createCreds(options: PublicKeyCredentialCreationOptions) {
    try {
      if (typeof options.challenge === 'string')
        options.challenge = this.base64UrlService.fromBase64Url(options.challenge);

      if (typeof options.user.id === 'string')
        options.user.id = this.base64UrlService.fromBase64Url(options.user.id);

      if (options.rp.id === null)
        options.rp.id = undefined;

      options.excludeCredentials!.forEach(cred => {
        if (typeof cred.id === 'string')
          cred.id = this.base64UrlService.fromBase64Url(cred.id);
      });

      const newCreds = await navigator.credentials.create({publicKey: options}) as PublicKeyCredential;
      const response = newCreds.response as AuthenticatorAttestationResponse;
      return this.mapAuthenticatorAttestationResponse(newCreds, response);
    } catch (error) {
      console.error(error)
      throw (error);
    }
  }

  public async register(email: string, credentialOptions: string, token: string) {
    try {
      const optionsJson: PublicKeyCredentialCreationOptionsJSON = JSON.parse(credentialOptions);
      const options = this.mapCredentialCreationOptions(optionsJson);
      const assertion = await this.createCreds(options);
      const management = this.cookieService.get('management') === 'true';
      const body = {userEmail: email, attestationResponse: assertion, token: token}
      const registerUrl = environment.services_api_url + environment.v1 + (management ? '/management' : '') + this.account_resource + this.register_resource;
      return this.getResponse('POST', registerUrl, body);
    } catch (error) {
      console.error(error);
      throw (error);
    }
  }

  public getAssertionOptions(userName: string): Observable<any> {
    const user = {UserEmail: userName};
    const assertionOptionsUrl = environment.services_api_url + environment.v1 + this.account_resource + this.get_assertion_options_resource;
    return this.getResponse('POST', assertionOptionsUrl, user);
  }

  private async verify(options: PublicKeyCredentialRequestOptions) {
    try {
      if (typeof options.challenge === 'string')
        options.challenge = this.base64UrlService.fromBase64Url(options.challenge);
      if (options.allowCredentials) {
        options.allowCredentials.forEach(cred => {
          if (typeof cred.id === 'string')
            cred.id = this.base64UrlService.fromBase64Url(cred.id);
        });
      }
      try {
        const creds = await navigator.credentials.get({publicKey: options}) as PublicKeyCredential;
        const response = creds.response as AuthenticatorAssertionResponse;
        return this.mapAuthenticatorAssertionResponse(creds, response);
      }
      catch (e) {
        try {
          const creds = await navigator.credentials.get({publicKey: options}) as PublicKeyCredential;
          const response = creds.response as AuthenticatorAssertionResponse;
          return this.mapAuthenticatorAssertionResponse(creds, response);
        }
        catch (error)
        {
          throw (error);
        }
      }
    } catch (error) {
      console.error(error);
      throw (error);
    }
  }

  public async login(userName: string, assertionOptions: string) {
    try {
      const optionsJson: PublicKeyCredentialRequestOptionsJSON = JSON.parse(assertionOptions);
      const options = this.mapCredentialRequestOptions(optionsJson);
      const assertion = await this.verify(options);
      const management = this.cookieService.get('management') === 'true';
      const assertionUrl = environment.services_api_url + environment.v1 + (management ? '/management' : '') + this.account_resource + this.assertion_resource;
      const body = {userEmail: userName, assertionResponse: assertion}
      return this.getResponse('PUT', assertionUrl, body);
    } catch (error) {
      console.error(error);
      throw (error);
    }
  }

  public startAttestation(email: string) {
    const user = {Email: email};
    const startAttestationUrl = environment.services_api_url + environment.v1 + this.account_resource + this.start_attestation_resource;
    return this.getResponse('POST', startAttestationUrl, user);
  }

  public checkPasskey(email: string, token: string) {
    if (!this.isWebAuthnPossible()) {
      throw new Error('WebAuthn is not supported in this browser');
    }
    const user = {UserEmail: email, CaptchaToken: token};
    const validationUrl = environment.services_api_url + environment.v1 + this.account_resource + this.validation_resource;
    return this.getResponse('POST', validationUrl, user);
  }

  // Map JSON to TypeScript objects

  private mapCredentialCreationOptions(options: PublicKeyCredentialCreationOptionsJSON): PublicKeyCredentialCreationOptions {
    const excludeCredentials: PublicKeyCredentialDescriptor[] = options.excludeCredentials!.map((cred: any) => {
      cred.id = this.base64UrlService.fromBase64Url(cred.id);
      return cred;
    });
    return {
      rp: options.rp,
      user: {
        id: this.base64UrlService.fromBase64Url(options.user.id),
        name: options.user.name,
        displayName: options.user.displayName
      },
      challenge: this.base64UrlService.fromBase64Url(options.challenge),
      pubKeyCredParams: options.pubKeyCredParams,
      timeout: options.timeout,
      excludeCredentials: excludeCredentials,
      authenticatorSelection: options.authenticatorSelection,
      attestation: options.attestation,
      extensions: options.extensions
    };
  }

  private mapAuthenticatorAttestationResponse(creds: PublicKeyCredential, response: AuthenticatorAttestationResponse) {
    return {
      id: this.base64UrlService.base64StringToUrl(creds.id),
      rawId: this.base64UrlService.toBase64Url(creds.rawId),
      type: creds.type,
      clientExtensionResults: creds.getClientExtensionResults(),
      response: {
        attestationObject: this.base64UrlService.toBase64Url(response.attestationObject),
        clientDataJSON: this.base64UrlService.toBase64Url(response.clientDataJSON),
        transports: response.getTransports ? response.getTransports() : []
      }
    };
  }

  private mapCredentialRequestOptions(optionsJson: PublicKeyCredentialRequestOptionsJSON): PublicKeyCredentialRequestOptions {
    const allowCredentials: PublicKeyCredentialDescriptor[] = optionsJson.allowCredentials!.map((cred: any) => {
      cred.id = this.base64UrlService.fromBase64Url(cred.id);
      return cred;
    });
    return {
      challenge: this.base64UrlService.fromBase64Url(optionsJson.challenge),
      timeout: optionsJson.timeout,
      rpId: optionsJson.rpId,
      allowCredentials: allowCredentials,
      userVerification: optionsJson.userVerification,
      extensions: optionsJson.extensions
    }
  }

  private mapAuthenticatorAssertionResponse(creds: PublicKeyCredential, response: AuthenticatorAssertionResponse) {
    return {
      id: creds.id,
      rawId: this.base64UrlService.toBase64Url(creds.rawId),
      type: creds.type,
      clientExtensionResults: creds.getClientExtensionResults(),
      response: {
        authenticatorData: this.base64UrlService.toBase64Url(response.authenticatorData),
        clientDataJSON: this.base64UrlService.toBase64Url(response.clientDataJSON),
        userHandle: response.userHandle && response.userHandle.byteLength > 0 ? this.base64UrlService.toBase64Url(response.userHandle) : undefined,
        signature: this.base64UrlService.toBase64Url(response.signature)
      }
    };
  }
}
