import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {CommonModule, JsonPipe, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {AuthService} from "../../../shared/services/auth.service";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../../shared/services/notification.service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {CodeInputModule} from "../../../shared/components/code-input/code-input.module";
import {NavigationService} from "../../../shared/services/navigation.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {CaptchaModalComponent} from "../../../shared/components/captcha-modal/captcha-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-check-code',
  standalone: true,
  imports: [
    CaptchaComponent,
    MatInput,
    MatOption,
    MatSelect,
    NgIf,
    ReactiveFormsModule,
    JsonPipe,
    TranslateModule,
    CommonModule,
    FaIconComponent,
    FormsModule,
    CodeInputModule,
    HeaderComponent
  ],
  templateUrl: './check-code.component.html',
  styleUrl: './check-code.component.css'
})
export class CheckCodeComponent implements OnInit, OnDestroy {
  redirect: any;
  email: any;
  token: any;
  code: any;
  disableResend = false;
  disableButton = false;
  spin = false;
  faSpinner = faSpinner;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('section') section: any;


  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params) {
          if (isAllowed(params['redirect'])) {
            this.redirect = params['redirect'];
            this.email = params['email'];
          } else {
            this.router.navigate(['/not-found']);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }


  async checkCode() {
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();
    const req = {
      email: this.email.trim(),
      code: this.code.toUpperCase(),
      captchaToken: token,
    };
    if (token) {
      this.spin = true;
      this.disableResend = true;
      this.disableButton = true;
      this.authService.confirmOtpSignIn(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe(resp => {
          const code = resp?.data?.code;
          if (resp?.responseCode === 'MissingPhone' || resp?.responseCode === 'UnconfirmedPhone') {
            this.notificationService.showAndSubscribe(resp?.message,
              'ACCEPT', '', false, '/confirm-phone',
              {redirect: this.redirect, code: code, fromLogin: true});
          } else if (resp?.error) {
            this.notificationService.showAndSubscribe(resp?.error?.message,
              'ACCEPT');
          } else {
            this.navigationService.navigateTo({
              url: '/login-success',
              redirect: this.redirect,
              code: code,
            });
          }
          this.spin = false;
          this.disableResend = false;
          this.disableButton = false;
        });
    }
  }

  async resendCode() {
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();

    const req = {
      email: this.email,
      captchaToken: token,
    };

    if (token) {
      this.spin = true;
      this.disableResend = true;
      this.authService.requestOtpSignIn(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe((resp): any => {
          this.spin = false;
          if (resp['success']) {
            this.disableResend = true;
            setTimeout(() => {
              this.disableResend = false;
            }, 10000);
          } else {
            this.notificationService.showAndSubscribe(resp?.error?.message, 'ACCEPT');
          }
        });
    }
  }

  codeCompleted(code: string) {
    this.code = code;
  }
}
