<div class="bg-gray-100 h-full">
  <!-- Header -->
  <app-header>

  </app-header>
  <div #section class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px]">

      <div class="my-8 -mb-0">
        <h3 class="font-Lato-Bold text-[24px] ">
          {{'UPDATE_PERSONAL_INFO' | translate}}
        </h3>
      </div>
      <!-- Form -->
      <form class="py-2" [formGroup]="infoForm">

        <!-- Name input-->
        <div class="mb-4">
          <input type="text" id="name" name="name"
                 formControlName="name"
                 class="w-full px-4 py-4 font-[16px] focus:outline-none
                 font-Lato-Light placeholder-[##727272]"
                 placeholder="{{'NAME_S' | translate}}*">
          <div class="text-start">
            <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
                <span
                  *ngIf="this.infoForm.controls['name'].hasError('required') && this.infoForm.controls['name'].touched">
                    {{'REQUIRED' | translate}}
                </span>
            </p>
          </div>
        </div>

        <!--Last name-->
        <div class="mb-4">
          <input type="text" id="firstName" name="firstName"
                 formControlName="lastName"
                 class="w-full px-4 py-4 font-[16px] focus:outline-none
                 font-Lato-Light placeholder-[##727272]"
                 placeholder="{{'SURNAMES' | translate}}*">
          <div class="text-start">
            <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
              <span
                *ngIf="this.infoForm.controls['lastName'].hasError('required') && this.infoForm.controls['lastName'].touched">
                  {{'REQUIRED' | translate}}
              </span>
            </p>
          </div>
        </div>
        <!-- Submit Button -->
        <button [ngClass]="{ 'opacity-50' : infoForm.invalid || spin, 'cursor-not-allowed' : infoForm.invalid || spin }"
                class="mt-6 rounded-full bg-[#FF6D03] py-2 text-[19px] text-white w-full justify-center items-center"
                (click)="onSubmit()" [disabled]= "infoForm.invalid || spin">
          <p *ngIf="!spin" class="font-Lato-Bold">
            {{'UPDATE' | translate}}
          </p>
          <div class="flex flex-row justify-center items-center">
            <fa-icon *ngIf="spin"
                     class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
          </div>
        </button>
      </form>
      <div>
          <span class="text-blue-600 font-Lato-Regular text-[16px] hover:cursor-pointer" (click)="goBack()">
            {{ 'GO_BACK_TO_SETTINGS' | translate }}
          </span>
      </div>
    </div>
  </div>
</div>
