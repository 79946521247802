import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from '@sentry/angular';
import {environment} from "../../../environments/environment";
import {AuthService} from "../services/auth.service";



@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor(private authService: AuthService) {
  }

  handleError(error: Error): any {
    if (!environment.isLocal) {
      Sentry.setUser({
        username: this.authService.currentUser?.email
      });
      Sentry.captureException(error);
    } else {
      throw error;
    }
  }
}
