import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect, MatSelectTrigger} from "@angular/material/select";
import {Location, NgClass, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {AuthService} from "../../../shared/services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {NotificationService} from "../../../shared/services/notification.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {User} from "../../../shared/models/user.model";
import {CookieService} from "ngx-cookie-service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-update-personal-information',
  standalone: true,
  imports: [
    CaptchaComponent,
    FaIconComponent,
    FormsModule,
    MatInput,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    HeaderComponent
  ],
  templateUrl: './update-personal-information.component.html',
  styleUrl: './update-personal-information.component.css'
})
export class UpdatePersonalInformationComponent implements OnInit, OnDestroy {
  infoForm: FormGroup = this.fb.group(
    {
      name: [null, [Validators.required, Validators.minLength(1)]],
      lastName: [null, [Validators.required, Validators.minLength(1)]],
    }
  );
  token: any;
  isHuman: boolean = false;
  spin: boolean = false;
  faSpinner = faSpinner;
  redirect: any;
  user: User = {};
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('section')
  section: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private cookieService: CookieService,
    private location: Location
  ) {
  }


  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params) {
          this.redirect = params['redirect'];
        }
      });
    this.initInfo();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  private initInfo() {
    const currentUser = this.cookieService.get('currentUser');
    this.user = JSON.parse(currentUser);
    const values = {
      name: this.user.firstName,
      lastName: this.user.lastName,
    }
    this.infoForm.reset(values);
  }


  onSubmit() {
    const req = {
      "newName": this.infoForm.get('name')?.value,
      "newLastName": this.infoForm.get('lastName')?.value,
    }
    this.spin = true;
    this.authService.changeName(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(resp => {
        this.spin = false;
        if (resp?.data) {
          this.router.navigate(['/personal-information'], {
            queryParams: {
              redirect: this.redirect,
            }
          });
        } else {
          this.notificationService.showAndSubscribe(resp?.error?.message,
            'ACCEPT',);
        }
      });
  }

  goBack() {
    this.location.back();
  }
}
