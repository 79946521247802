<!-- Header -->
<app-header>

</app-header>
<div class="bg-gray-100 min-h-screen">
  <div class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px]">

      <div class="py-6">
        <h3 class="font-Lato-Bold text-[24px] ">
          {{'404' | translate}}
        </h3>

        <div class="h-6 w-30">
          <p class="mt-4 font-Lato-Regular text-[14px] text-center mb-6">
            {{'NOT_FOUND_PAGE_MESSAGE' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
