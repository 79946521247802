<div class="bg-gray-100 h-full">
  <!-- Header -->
  <app-header>

  </app-header>
  <div #section class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px]">

      <div class="flex flex-col items-center justify-center my-8">
        <h3 class="font-Lato-Bold text-[24px] ">
          {{'CHECK_CODE' | translate}}
        </h3>
        <p class="mt-4 font-Lato-Regular flex justify-center text-center text-[14px]">
          {{'INTRODUCE_VERIFICATION_CODE' | translate}}
        </p>
      </div>
      <!-- Form -->
      <form>
        <code-input
          [codeLength] = 6
          [inputType]="'text'"
          [inputMode]="'text'"
          [isCharsCode] = true
          (codeCompleted)="codeCompleted($event)"
          (codeChanged)="codeCompleted($event)"
        >
        </code-input>

        <!-- Submit Button -->
        <button type="submit" [ngClass]="{'opacity-50' : disableButton || code?.length !== 6, 'cursor-not-allowed' : disableButton || code?.length !== 6 }"
                [disabled]="disableButton || code?.length !== 6"
                (click)="checkCode()"
                class="mt-6 rounded-full bg-[#FF6D03] py-2 text-[19px] text-white mb-6 w-full
                 flex  justify-center  ">
          <p *ngIf="!spin" class="font-Lato-Bold">
            {{'CHECK_CODE' | translate}}
          </p>
          <div *ngIf="spin" class="flex flex-row justify-center items-center">
            <fa-icon
              class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
              [icon]="faSpinner">
            </fa-icon>
          </div>
        </button>
        <button type="submit" class="mb-44 text-blue-600" [ngClass]="{'text-gray-300' : disableResend, 'cursor-not-allowed' : disableResend}"
                [disabled]="disableResend"
                (click)="resendCode()">
            <span class="font-Lato-Regular text-[16px]">
              {{'RESEND_CODE' | translate}}
            </span>
        </button>
      </form>
    </div>
  </div>
</div>
