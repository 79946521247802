import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {JsonPipe, NgClass, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {AuthService} from "../../../shared/services/auth.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";
import {Country} from "ngx-mat-intl-tel-input/lib/model/country.model";
import {PhoneNotification} from "../../../shared/models/phone_notification.model";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-confirm-phone',
  standalone: true,
  imports: [
    CaptchaComponent,
    FaIconComponent,
    FormsModule,
    NgIf,
    TranslateModule,
    ReactiveFormsModule,
    NgClass,
    HeaderComponent,
    NgxMatIntlTelInputComponent,
    MatRadioGroup,
    MatRadioButton,
    JsonPipe
  ],
  templateUrl: './confirm-phone.component.html',
  styleUrl: './confirm-phone.component.css'
})
export class ConfirmPhoneComponent implements OnInit, OnDestroy {
  faSpinner = faSpinner;
  redirect: any;
  email: any;
  spin: any;
  disableResendCode: boolean = false;
  dialCode: any;
  countryIso2: any;
  fromLogin: boolean = false;
  phone: any;
  destroy$: Subject<boolean> = new Subject<boolean>();


  @ViewChild('section')
  section: any;

  phoneForm = this.fb.group(
    {
      phone: [null, Validators.required],
      notificationType: ['1', Validators.required]
    }
  );

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    const paramMap = this.route.snapshot.queryParamMap;
      if (paramMap.get('code')) {
        this.redirect = paramMap.get('redirect');
        this.fromLogin = paramMap.get('fromLogin') === "true";
        this.authService.getTokensWithCode(paramMap.get('code') as string)
          .pipe(takeUntil(this.destroy$))
          .subscribe(resp => {
          this.authService.getUserInfo().subscribe(info => {
            if(info?.data?.pendingPhone?.number){
              this.phone = info?.data?.pendingPhone?.number;
              const phoneNumber: any = `+${info?.data?.pendingPhone?.countryPrefix}${this.phone}`
              this.phoneForm.patchValue({
                phone: phoneNumber
              });
            }
          });
        });
      }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  onConfirm() {
    let aux = this.phoneForm.get('phone')?.value ?? '';
    aux = aux.slice(1)
    let phoneNumber = aux?.replace(this.dialCode, '');
    let notificationType = parseInt(this.phoneForm.get('notificationType')?.value ?? '1', 10);
    const phoneInfo: PhoneNotification = {
      phone: {
        number: phoneNumber,
        countryIso2: this.countryIso2,
        countryPrefix: this.dialCode
      },
      notificationType: notificationType,
    }
    this.spin = true;
    this.authService.changePhone(phoneInfo)
      .pipe(takeUntil(this.destroy$))
      .subscribe(resp => {
      if (resp?.success) {
        this.router.navigate(['/verify-change-phone'], {
          queryParams: {
            redirect: this.redirect,
            fromLogin: this.fromLogin,
            notificationType: notificationType,
          }, replaceUrl: true
        });
      } else {
        this.notificationService.showAndSubscribe(resp?.error?.message, 'ACCEPT');
      }
      this.spin = false;
    });
  }

  changePhone(phone: Country) {
    this.countryIso2 = phone.iso2;
    this.dialCode = phone.dialCode;
  }
}
