<!-- Header -->
<app-header>

</app-header>
<div class="bg-gray-100 min-h-screen">
  <div #section class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px]">

      <div class="py-6">
        <h3 class="font-Lato-Bold text-[24px] ">
          {{'SUCCESSFUL_REGISTER' | translate}}
        </h3>

        <div class="h-6 w-30">
          <p class="mt-4 font-Lato-Regular text-[14px] text-justify mb-6">
            {{'REGISTER_SUCCESS_REDIRECT_MESSAGE' | translate}}
            <span
              class="mt-6 font-Lato-Regular text-[#18396C] py-2 text-[16px] px-2 underline mb-12 hover:cursor-pointer"
              (click)=onSubmit()>
              {{'CLICK_HERE' | translate}}
            </span>
            {{'TO_CONTINUE' | translate}}
          </p>
          <img
            src="/assets/images/Successful%20register-pana@2x.png" alt="Successful register">
        </div>
      </div>
    </div>
  </div>
</div>
