<!-- Header -->
<app-header>

</app-header>
<div #section class="bg-gray-100 justify-center flex flex-col content-center items-center text-center">

  <div class="w-[333px]">

    <div class="my-8 -mb-0">
      <h3 class="font-Lato-Bold text-[24px] ">
        {{'REGISTER_USER' | translate}}
      </h3>
    </div>
    <!-- Form -->
    <form class="mt-6 mb-2" [formGroup]="registerForm">

      <!-- Name input-->
      <div class="mb-4">
        <input type="text" id="name" name="name"
               formControlName="name"
               class="w-full px-4 py-4 font-[16px] focus:outline-none
               font-Lato-Light placeholder-[##727272]"
               placeholder="{{'NAME_S' | translate}}*">
        <div class="text-start">
          <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
              <span
                *ngIf="this.registerForm.controls['name'].hasError('required') && this.registerForm.controls['name'].touched">
                  {{'REQUIRED' | translate}}
              </span>
          </p>
        </div>
      </div>

      <!--Last name-->
      <div class="mb-4">
        <input type="text" id="firstName" name="firstName"
               formControlName="lastName"
               class="w-full px-4 py-4 font-[16px] focus:outline-none
               font-Lato-Light placeholder-[##727272]"
               placeholder="{{'SURNAMES' | translate}}*">
        <div class="text-start">
          <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
            <span
              *ngIf="this.registerForm.controls['lastName'].hasError('required') && this.registerForm.controls['lastName'].touched">
                {{'REQUIRED' | translate}}
            </span>
          </p>
        </div>
      </div>

      <!--    Phone input-->
      <div class="text-start mb-10">
        <ngx-mat-intl-tel-input
          class="mat-intl-tel-input-class py-4 relative text-gray-#727272 bg-white font-Lato-Regular
          pl-4 text-base flex flex-1 items-center"
          [enablePlaceholder]="true"
          [preferredCountries]="['us', 'cu']"
          inputPlaceholder="{{'PHONE_NUMBER' | translate}}*"
          (countryChanged)="changePhone($event)"
          formControlName="phone"
          [enableSearch]="true"
          name="phone"
        >
        </ngx-mat-intl-tel-input>
        <div *ngIf="registerForm.get('phone')?.invalid && (registerForm.get('phone')?.dirty || registerForm.get('phone')?.touched)">
          <div class="relative font-Lato-Regular text-sm text-red-300 h-3"
               *ngIf="registerForm.get('phone')?.hasError('required')">{{ 'REQUIRED' | translate }}</div>
          <div class="relative font-Lato-Regular text-sm text-red-300 h-3"
               *ngIf="registerForm.get('phone')?.hasError('validatePhoneNumber')">{{ 'INVALID_PHONE_NUMBER' | translate }}</div>
        </div>
      </div>

      <!-- Email Input -->
      <div class="mb-4 -mt-4">
        <input type="email" id="email" name="email"
               formControlName="email"
               class="w-full px-4 py-4 font-[16px] focus:outline-none
               font-Lato-Light placeholder-[##727272]"
               placeholder="{{'ELECTRONIC_EMAIL' | translate}}*">
        <div class="text-start">
          <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
            <span
              *ngIf="this.registerForm.controls['email'].hasError('required') && this.registerForm.controls['email'].touched">
                {{'REQUIRED' | translate}}
            </span>
            <span
              *ngIf="this.registerForm.controls['email'].hasError('pattern') && !this.registerForm.controls['email'].hasError('required') && this.registerForm.controls['email'].touched">
                  {{'WRONG_FORMAT' | translate}}
            </span>
          </p>
        </div>
      </div>

      <!-- Password Input -->
      <app-password-input [passwordControl]="passwordControl" placeholder="{{'PASSWORD' | translate}}" id="password">
      </app-password-input>

      <!-- Confirm Password -->
      <app-password-input (keydown.enter)="onSubmit()" [passwordControl]="confirmPasswordControl" placeholder="{{'CONFIRM_PASSWORD' | translate}}"
                          id="confirmPassword">
      </app-password-input>

      <div *ngIf="registerForm.controls['password'].value !== registerForm.controls['confirmPassword'].value
                  && registerForm.controls['password'].touched && registerForm.controls['confirmPassword'].touched"
           class="text-start">
        <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
          <span>
              {{'MUST_MATCH' | translate}}
          </span>
        </p>
      </div>

      <p class="font-Lato-Regular text-[12px] text-[#5D5D5D] text-start">
        {{'INDICATES_THAT_THE_FIELD_IS_REQUIRED' | translate}}
      </p>
    </form>

    <!-- Submit Button -->
    <button
      [ngClass]="{ 'opacity-50' : registerForm.invalid || spin, 'cursor-not-allowed' : registerForm.invalid || spin }"
      class="mt-6 rounded-full bg-[#FF6D03] py-2 text-[19px] text-white w-full justify-center items-center"
      (click)="onSubmit()" [disabled]="registerForm.invalid || spin">
      <p *ngIf="!spin" class="font-Lato-Bold">
        {{'REGISTER' | translate}}
      </p>
      <div class="flex flex-row justify-center items-center">
        <fa-icon *ngIf="spin"
                 class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                 [icon]="faSpinner">
        </fa-icon>
      </div>
    </button>
    <p class="font-Lato-Regular mb-10 ">
      {{'AGREE_REGISTER' | translate}}
      <a href="https://www.katapulk.com/contents/terms" target="_blank" class="text-blue-600">
        {{'AGREE_SCND' | translate}}
      </a>
    </p>

    <p class="font-Lato-Regular mb-20">
      {{'DO_YOU_HAVE_ACCOUNT' | translate}}
      <span class="text-blue-600 hover:cursor-pointer" (click)="navigateTo('/sign-in', redirect)">
        {{'LOG_IN' | translate}}
      </span>
    </p>
  </div>
</div>
