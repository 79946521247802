<div class="bg-gray-100 h-full">
  <!-- Header -->
  <app-header>

  </app-header>
  <div #section class="justify-center flex flex-col content-center items-center text-center">

    <div class="w-[333px]">

      <div class="my-8">
        <h3 class="font-Lato-Bold text-[24px] mb-4">
          {{'UPDATE_EMAIL' | translate}}
        </h3>
        <p class="font-Lato-Regular text-[14px]">
          {{'UPDATE_EMAIL_MESSAGE' | translate}}
        </p>
      </div>
      <!-- Form -->
      <form (ngSubmit)="sendCode()">
        <div class="text-start mb-8">
          <input type="text" id="code" name="code"
                 class="w-full px-4 py-4  font-[16px] focus:outline-none
                 font-Lato-Light placeholder-[##727272]" [formControl]="emailControl"
                 placeholder="{{'NEW_EMAIL' | translate}}*">
          <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
                <span
                  *ngIf="emailControl.hasError('required') && emailControl.touched">
                    {{'REQUIRED' | translate}}
                </span>
          </p>
        </div>
        <!-- Submit Button -->
        <button [ngClass]="{'opacity-50' : emailControl.hasError('required') || spin || !emailControl.touched,
          'cursor-not-allowed' : emailControl.hasError('required') || spin || !emailControl.touched }"
                type="submit"  class="rounded-full bg-[#FF6D03] py-2 text-[19px] text-white mb-6 w-full"
                [disabled]="emailControl.hasError('required') || spin || !emailControl.touched">
          <p *ngIf="!spin" class="font-Lato-Bold text-[19px]">
            {{'UPDATE' | translate}}
          </p>
          <div class="flex flex-row justify-center items-center">
            <fa-icon *ngIf="spin"
                     class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
          </div>
        </button>
      </form>
      <div>
          <span class="text-blue-600 font-Lato-Regular text-[16px] hover:cursor-pointer" (click)="goBack()">
            {{ 'GO_BACK_TO_CONTACT_INFO' | translate }}
          </span>
      </div>
    </div>
  </div>
</div>
