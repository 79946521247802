import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect, MatSelectTrigger} from "@angular/material/select";
import {NgClass, NgIf} from "@angular/common";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../shared/services/auth.service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {NavigationService} from "../../../shared/services/navigation.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {CaptchaModalComponent} from "../../../shared/components/captcha-modal/captcha-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {NotificationService} from "../../../shared/services/notification.service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-recover-password',
  standalone: true,
  imports: [
    CaptchaComponent,
    MatInput,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    FaIconComponent,
    HeaderComponent
  ],
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.css'
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
  faSpinner = faSpinner;
  token: any;
  redirect: any;
  disableButton: any;
  spin = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('section')
  section: any;


  // methodSelectControl: FormControl = new FormControl({value: 'email', disabled: true}, Validators.required);
  emailControl: FormControl = new FormControl(null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
  // phoneControl: FormControl = new FormControl('+1', Validators.required);


  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private navigationService: NavigationService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this.redirect = this.route.snapshot.queryParams['redirect'];
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  async onPasswordReset() {
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();
    const req = {
      email: this.emailControl.value,
      captchaToken: token,
    }
    if (token) {
      this.spin = true;
      this.disableButton = true;
      this.authService.passwordResetToken(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe(resp => {
        if (resp?.success) {
          this.navigationService.navigateTo({
            url: '/confirm-reset-password',
            redirect: this.redirect,
            email: this.emailControl.value.trim()
          });
        } else {
          this.notificationService.showAndSubscribe(resp?.error?.message, 'ACCEPT');
        }
        this.spin = false;
        setTimeout(() => {
          this.disableButton = false;
        }, 5000);
      });
    }
  }
}
