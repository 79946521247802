import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {CommonModule, Location} from "@angular/common";
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";
import {TranslateModule} from "@ngx-translate/core";
import {AuthService} from "../../../shared/services/auth.service";
import {Subject, takeUntil} from "rxjs";
import {NotificationService} from "../../../shared/services/notification.service";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {CookieService} from "ngx-cookie-service";
import {PasswordInputComponent} from "../../../shared/components/password-input/password-input.component";
import {NavigationService} from "../../../shared/services/navigation.service";
import {HeaderComponent} from "../../../shared/components/header/header.component";
import {MatDialog} from "@angular/material/dialog";
import {CaptchaModalComponent} from "../../../shared/components/captcha-modal/captcha-modal.component";

@Component({
  selector: 'app-sign-in-password',
  standalone: true,
  imports: [
    CaptchaComponent,
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    PasswordInputComponent,
    HeaderComponent
  ],
  templateUrl: './sign-in-password.component.html',
  styleUrl: './sign-in-password.component.css'
})
export class SignInPasswordComponent implements OnDestroy, OnInit{
  spin: boolean = false;
  faSpinner = faSpinner;
  token: string | null = null;
  email: string = '';
  redirect: any;
  management: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('section') section: any;

  public loginForm = this.fb.group(
    {
      email: [{value : '',disabled: false}, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: [null, [Validators.required, Validators.minLength(6)]],
    }
  );

  get passwordControl(): FormControl {
    return this.loginForm.get('password') as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private navigationService: NavigationService,
    private dialog: MatDialog,
    private location: Location
  ) {
  }

  ngOnInit() {
    const paramMap = this.route.snapshot.queryParamMap
    if (isAllowed(paramMap.get('redirect'))) {
      this.redirect = paramMap.get('redirect');
      const management = paramMap.get('management') ?? "false";
      this.cookieService.deleteAll();
      this.cookieService.set('management', management);
    } else {
      this.router.navigate(['/not-found']);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  async onSubmit() {
    const formData: any = this.loginForm?.value;
    formData.email = formData?.email.trim();
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();
    formData.captchaToken = token;
    if (token) {
      this.spin = true;
      this.authService.login(formData)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (loginResponse: any) => {
            if (loginResponse?.error) {
              const code = loginResponse?.error.data?.code;
              if (loginResponse?.error.responseCode === "UnconfirmedEmail") {
                this.notificationService.showAndSubscribe(loginResponse?.error.message,
                  'ACCEPT', '', false, '/confirm-register',
                  {redirect: this.redirect, email: this.loginForm?.value?.email, fromLogin: true});
              } else if (loginResponse?.error.responseCode === 'MissingPhone' || loginResponse?.error.responseCode === 'UnconfirmedPhone') {
                this.notificationService.showAndSubscribe(loginResponse?.error.message,
                  'ACCEPT', '', false, '/confirm-phone',
                  {redirect: this.redirect, code: code, fromLogin: true});
              } else {
                this.notificationService.showAndSubscribe(loginResponse?.error?.message, 'ACCEPT');
              }
            } else {
              const code = loginResponse?.data?.code;
              this.router.navigate(['/login-success'], {
                queryParams: {
                  redirect: this.redirect,
                  code: code,
                }, replaceUrl: true
              });
            }
            this.spin = false;
          },
          error: () => {
          },
        });
    }
  }


  navigateTo(url: string, redirect: string, code?: string, email?: string) {
    this.navigationService.navigateTo({
      url: url,
      redirect: redirect,
      code: code,
      email: email
    });
  }

  goBack() {
    this.location.back();
  }
}
